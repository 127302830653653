import React from "react";

// Assets | ICONS :
import { Calendar1, CalendarTick, CardAdd, CardSend, Coin1, MoneyRecive, People, VideoTime, Wallet3, Setting2, I24Support } from "iconsax-react";
import { Profile2User } from "iconsax-react";
import { PresentionChart } from "iconsax-react";
import { Element3, Book } from "iconsax-react";

// Components :
import Home from "./Components/Home/main";
import User from "./Components/Users/User";
import Blogs from "./Components/Blogs/Blogs";
import Roles from "./Components/Roles/Roles";
import Books from "./Components/Books/Books";
import PurchasedBook from "./Components/PurchasedBook/PurchasedBook";
import Meetings from "./Components/Meetings/Meetings";
import Transaction from "./Components/Transactions/Transaction";
import Profile from "./Components/Profile/Profile";
import { FaChalkboardTeacher } from "react-icons/fa";
import Subscription from "./Components/Subscription/Subscription";
import Support from "./Components/Support/Support";
import Events from "./Components/Events/Events";
import Marquee from "./Components/Marquee/Marquee";
import VideoCallingTEMP from "./Components/VideoCalling/VideoCallingTEMP";
import VideoCalling from "./Components/VideoCalling/VideoCalling";
import Wallet from "./Components/Wallet/Wallet";
import Settings from "./Components/Settings/Settings";
import Donation from "./Components/Donation/Donation";
import Withdrawal from "./Components/Withdrawal/Withdrawal";
import { BiDonateBlood } from "react-icons/bi";
import { MdOutlineEventRepeat } from "react-icons/md";

// import SupportFF from "./Components/Support/Component/SupportFF";


const getSideBarData = ({ label, key, icon, children, element, isDefault, permissions, restrictTo, allowTo }) => {
    return {
        key,
        icon,
        children,
        label,
        element,
        isDefault,
        permissions,
        restrictTo,
        allowTo,
    };
}

const routsList = [
    getSideBarData({ label: 'Dashboard', key: '/', icon: <Element3 />, element: Home, isDefault: true }),
    getSideBarData({ label: 'Profile', key: '/profile', icon: <FaChalkboardTeacher style={{ marginRight: "5px" }} />, element: Profile, isDefault: true }),
    getSideBarData({ label: 'Roles', key: '/roles', icon: <Profile2User />, element: Roles }),
    getSideBarData({ label: 'Users', key: '/users', icon: <People />, element: User }),
    getSideBarData({ label: 'Meetings', key: '/meetings', icon: <VideoTime />, element: Meetings }),
    getSideBarData({ label: 'Blogs', key: '/blogs', icon: <PresentionChart />, element: Blogs }),
    getSideBarData({ label: 'Books', key: '/books', icon: <Book />, element: Books }),
    getSideBarData({ label: 'Purchased Books', key: '/purchasedbooks', icon: <Book />, element: PurchasedBook, restrictTo: "student" }),
    getSideBarData({ label: 'Transactions', key: '/transactions', icon: <Coin1 />, element: Transaction }),
    getSideBarData({ label: 'Subscription', key: '/subscribtion', icon: <CardAdd />, element: Subscription }),
    getSideBarData({ label: 'Support', key: '/support', icon: <I24Support />, element: Support }),

    getSideBarData({ label: 'Donation', key: '/donation', icon: <BiDonateBlood size={25} />, element: Donation }),
    getSideBarData({ label: 'Events', key: '/events', icon: <CalendarTick />, element: Events }),
    getSideBarData({ label: 'Marquee', key: '/marquee', icon: <MdOutlineEventRepeat size={24} />, element: Marquee }),
    // getSideBarData({ label: 'Calling', key: '/video', icon: <CalendarTick />, element: CallMeeting }),
    // getSideBarData({ label: 'Video Call', key: '/call', icon: <CalendarTick />, element: VideoCalling, isDefault: true }),

    getSideBarData({ label: 'Wallet', key: '/wallet', icon: <Wallet3 />, element: Wallet, allowTo: "teacher, superadmin" }),

    getSideBarData({ label: 'Set Charges', key: '/settings', icon: <Setting2 />, element: Settings }),
    getSideBarData({ label: 'Withdrawal', key: '/Withdrawal', icon: <Wallet3 />, element: Withdrawal })
];



export default routsList;