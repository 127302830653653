import React, { useState, useEffect } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { AiOutlineFileProtect } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { PiStudentDuotone } from 'react-icons/pi';
import { VideoTime } from 'iconsax-react';
import { toast } from "react-toastify";
import { PiStudentFill } from "react-icons/pi";
import { RiPresentationFill } from "react-icons/ri";
import { MdAssignment } from "react-icons/md";
import { GrSchedule } from "react-icons/gr";

import dashboardCardPersonIcon from "../../../../../../Assets/Images/dashboardCardPersonIcon.png"
import dashboardCardPersonIcon2 from "../../../../../../Assets/icons/ph_student.png";
import dashboardCardPersonIcon3 from "../../../../../../Assets/icons/Group.png";
import dashboardCardPersonIcon4 from "../../../../../../Assets/icons/material-symbols_assignment-outline.png";
import dashboardCardPersonIcon5 from "../../../../../../Assets/icons/akar-icons_schedule.png";
import "./Cards.scss";
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import StatsAPI from "./../../../../../../API/stats";
import ClassAPI from "./../../../../../../API/classstats";
import { GetMeetingDashboardData } from "./../../../../../../API/meeting";

const Cards = ({ staticsData }) => {
    const [CardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const gettingDashboardData = async () => {
        try {
            const madrasaUserData = JSON.parse(localStorage.getItem("madrasaUserData"));
            const role = madrasaUserData._id;
            const res = await GetMeetingDashboardData(role);

            setCardsData([
                {
                    icon: PiStudentFill,
                    image: dashboardCardPersonIcon,
                    static: res.data.totalUniqueParticipants,
                    value: res.data.totalUniqueParticipants,
                    name: 'Students',
                    route: 'users',
                    status: '/dashboard/meetings',
                },
                {
                    icon: RiPresentationFill,
                    image: dashboardCardPersonIcon,
                    static: res.data.totalMeetings,
                    value: res.data.totalMeetings,
                    name: 'Classes',
                    route: 'users',
                    status: '/dashboard/meetings',
                },
                {
                    icon: MdAssignment,
                    image: dashboardCardPersonIcon,
                    static: res.data.pendingMeetings,
                    value: res.data.pendingMeetings,
                    name: 'Pending Classes',
                    route: 'users',
                    status: '/dashboard/meetings',
                },
                {
                    icon: GrSchedule,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'Schedule',
                    route: 'meetings',
                    status: '/dashboard/profile',
                },
            ]);
        } catch (error) {
            toast.error(error.message || 'Failed to fetch dashboard data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        gettingDashboardData();
    }, []);

    const navigate = useNavigate();
    const handleChangeRoute = (val) => {
        navigate(`${val.status}`);
    };

    const renderCard = (data, index) => (
        <div className="hoverEffect" key={index} onClick={() => handleChangeRoute(data)}>
            <div className="card">
                <div className="iconBox">
                    <data.icon size={26} color="#575757" />
                    <div
                        className={`${data.static < 0 ? 'counts countDanger' : 'counts'}`}
                        style={data.static < 0 ? { background: 'var(--dangerGradient)' } : {}}
                    >
                        {data.static}
                        {data.static < 0 ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
                    </div>
                </div>
                <div className="detials">
                    <div className="title">{data.name}</div>
                </div>
            </div>
        </div>
    );

    const renderFourthCard = () => (
        <div className="hoverEffect" onClick={() => handleChangeRoute(CardsData[3])}>
            <div className="card" style={{ /* custom styles for the fourth card */ }}>
                <div className="iconBox">
                    <GrSchedule size={26} color="#575757" />
                </div>
                <div className="detials">
                    <div className="title">Schedule & Payments</div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="cardsContainer">
            {loading ? (
                <>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </>
            ) : (
                <>
                    {CardsData.slice(0, 3).map((data, index) => renderCard(data, index))}
                    {renderFourthCard()}
                </>
            )}
        </div>
    );
};

export default Cards;
