import { Divider, Col, Row, Card } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler, ScriptableContext } from 'chart.js';
import { DatePicker } from 'antd'
import { Line } from "react-chartjs-2";
import "./wallet.scss";

import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WalletIcon from '@mui/icons-material/Wallet';
import { Typography } from 'antd';
import Table from '../Users/Component/table/Table';
import { FaCaretRight } from 'react-icons/fa';
import { Wallet1 } from 'iconsax-react';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { GetAllTransactionsAPI, GetBalanceAPI } from 'API/transaction';
import { GettingAllWalletDetails } from 'API/wallet';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ROLES from 'Utils/Roles';


const { Title } = Typography;
let { RangePicker } = DatePicker;


ChartJS.register(
    'CategoryScale',
    'LinearScale',
    'PointElement',
    'LineElement',
    'Title',
    'Tooltip',
    'Legend',
    'Filler'
);
const Wallet = () => {

    const UserData = useSelector(state => state.userData)
    const Balance = useSelector(state => state.balance)

    const [walletData, setWalletData] = useState(null);
    const [transactionData, setTransactionData] = useState([]);

    const [transactionLoading, setTransactionLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState("all")
    const [selectedWallet, setSelectedWallet] = useState(null)
    const Navigate = useNavigate()





    let data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Real Values",
                data: [9, 8, 15, 8, 21, 17, 23, 16, 27, 35, 27, 32, 31, 33,],
                fill: "start",
                borderColor: "#FFC01E",
                backgroundColor: function (context) {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(255, 192, 30, 0.4)");
                    gradient.addColorStop(1, "rgba(255, 219, 126, 0.1)");
                    return gradient;
                },
                pointBorderColor: "#FCD117",
                tension: 0.4,
                borderWidth: 3,
                pointHoverRadius: 6,
                pointBorderWidth: 6,
                pointRadius: 2,
            }]
    }


    let options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
        },
        elements: {
            point: {
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 12
                    },
                    color: "#006400"
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 12
                    },
                    color: "#006400",
                    callback: function callback(value) {
                        return "$" + value;
                    }
                },
                grid: {
                    color: "#EDEFF3",
                    drawBorder: true
                },
                border: {
                    display: false,
                    dash: [5, 5]
                }
            }
        }
    }

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            align: "left",
            render: (_, data) => data?.createdAt?.slice(0, 10) || null,
            ellipsis: true,
        },
        ...(UserData && UserData?.isSuperAdmin || UserData?.role?.name != "student" ? [{
            title: "Buyer Name",
            dataIndex: "buryerName",
            key: "buryerName",
            align: "left",
            render: (_, data) =>
                `${data?.buyer?.firstName ? data?.buyer?.firstName : data?.shippingDetails?.firstName} ${data?.buyer?.lastName ? data?.buyer?.lastName : data?.shippingDetails?.lastName}`,
            ellipsis: true,
        }] : []),
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            // ellipsis: true,
            align: "center",
            render: (_, data) => `${data?.orderType?.toLocaleUpperCase()}`,
            sorter: (a, b) => a?.title?.localeCompare(b?.title),
            className: "centerAntTableHeader"
        },
        {
            title: "Total Balance",
            dataIndex: "balance",
            key: "balance",
            render: (_, data) => <>${`${parseFloat(data?.balance).toFixed(1)}`}</>,
            align: "center",
            ellipsis: true,
        },
    ];

    const gettingWalletData = async () => {
        let res = await GettingAllWalletDetails()
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setWalletData(res.data?.result)
        }
    }
    const getAllTransactions = async () => {
        setTransactionLoading(true);
        const res = await GetAllTransactionsAPI();
        if (res.error != null) {
            toast.error(res.error);
        } else {
            let transactionData = res?.data?.result;

            if (transactionData && transactionData.length >= 1) {
                let result;
                let maxResult = [];
                if (UserData.role?.name == ROLES.Teacher) {
                    result = transactionData.map(transaction => {
                        let sellers = transaction.sellers?.find(val => val?.userData?._id == UserData?._id);
                        return {
                            ...transaction,
                            sellers,
                            sources: sellers?.sources,
                            balance: sellers?.balance,
                            orderPrice: sellers?.orderPrice,
                            sellerCharges: sellers?.charges
                        }
                    });
                    maxResult.push(result[0]);
                    maxResult.push(result[1]);
                    maxResult.push(result[2]);
                } else {

                    result = transactionData;
                    maxResult.push(result[0]);
                    maxResult.push(result[1]);
                    maxResult.push(result[2]);
                }
                setTransactionData(maxResult || []);
            } else {
                setTransactionData([]);
            }

        }
        setTransactionLoading(false);
    };


    useEffect(() => {
        getAllTransactions();
        gettingWalletData()
    }, [])


    return (
        <>
            <div className="walletContainer">
                <div className="flexLineSpace">
                    <div className="heading upper customhead">
                        WALLET
                    </div>
                </div>
                <div className="ViewBodyMain">
                    <div className="teacherAmount">
                        <div class=" row">
                            <div className='imgLogo'><WalletIcon className='topIcons' /></div>
                            <div className='column'>
                                Current Balance
                                <h2 className='heading'>${walletData?.availableBalance ? (Number(walletData?.availableBalance) / 100).toFixed(2) : "00.00"}</h2>
                                <p>in one year</p>
                            </div>
                        </div>
                        <Divider type="vertical" className='devider' />
                        <div class=" row">
                            <div className='imgLogo'><AccountBalanceIcon className='topIcons' /></div>
                            <div className='column'>
                                Total Balance
                                <h2 className='heading'>${walletData?.totalBalance ? (Number(walletData?.totalBalance) / 100).toFixed(2) : "00.00"}</h2>
                                <p>in one year</p>
                            </div>
                        </div>
                        <Divider type="vertical" className='devider' />
                        <div class=" row">
                            <div className='imgLogo'><BiMoneyWithdraw className='topIcons' /></div>
                            <div className='column'>
                                Withdrawal Amount
                                <h2 className='heading'>${walletData?.totalWithdraw ? (Number(walletData?.totalWithdraw) / 100).toFixed(2) : "00.00"}</h2>
                                <p>in one year</p>
                            </div>
                        </div>
                    </div>
                    <div className='transitionHistory'>
                        <div className="subHeading">Transition History</div>
                        <div className='transitionGraph'>
                            <div className="flexLineSpace">
                                <Title level={5}>Transaction Graph</Title>
                                <RangePicker className='datePicker' />
                            </div>
                            <div className="chart">
                                <Line options={options} data={data} />
                            </div>
                        </div>

                    </div>


                    <div className='recordTable'>
                        <div className="table">
                            <Table columns={columns} rows={transactionData} />
                        </div>
                        <div className='balanceCard'>
                            <Card >
                                <div className='cardHeading'>
                                    <h3 className='balanceHeading'>${walletData?.availableBalance ? (Number(walletData?.availableBalance) / 100).toFixed(2) : "00.00"}</h3>
                                    <p className='activeBalance'>Active Balance</p>
                                </div>
                                <div className='cardDetails'>
                                    <div className='detailDiv'>
                                        <div className='logoHeading'>
                                            <div className="iconBox">
                                                <FiArrowUp color='green' className='icon' />
                                            </div>
                                            <h5>Incomes</h5>
                                        </div>
                                        <h5>$ 1699.0</h5>
                                    </div>
                                    <div className='detailDiv'>
                                        <div className='logoHeading'>
                                            <div className="iconBox">
                                                <FiArrowDown color='#f7a928' className='icon' />
                                            </div>
                                            <h5>Taxes</h5>
                                        </div>
                                        <h5>$ -799.0</h5>
                                    </div>
                                    <div className='detailDiv'>
                                        <div className='logoHeading'>
                                            <div className="iconBox">
                                                <FiArrowDown color='#f7a928' className='icon' />
                                            </div>
                                            <h5>Platform Charges</h5>
                                        </div>
                                        <h5>$ -199.0</h5>
                                    </div>
                                </div>
                                <div className="cardButton">
                                    <button type="button" onClick={() => Navigate("/dashboard/Withdrawal")}>Withdrawal Request  <FaCaretRight color='#f7a928' size={30} className='icon' /></button>
                                </div>
                            </Card>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Wallet;