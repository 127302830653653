import React, { useEffect, useState } from "react";

// CSS :
import "./Donation.scss";
import AllDonationUser from "./Component/AllSupportUser/AllDonationUser";
import { GetAllDonationsAPI } from "API/transaction";
import { toast } from "react-toastify";

const Donation = (props) => {

  let RoutePermissions = props?.permissions || [];


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);


  const gettingDonations = async () => {
    let res = await GetAllDonationsAPI()
    if (res.error != null) {
      toast.error(res.error)
    } else {
      setData(res?.data?.result)
    }
  }
  useEffect(() => {
    gettingDonations()
  }, [])

  return (
    <>
      <div className="dashboardUsersContainer">
        <AllDonationUser data={data} setData={setData} reload={reload} setReload={setReload} loading={loading} setLoading={setLoading} />
      </div>
    </>
  );
};

export default Donation;
