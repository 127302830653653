import React, { useEffect, useState } from 'react';

// MUI | ANT-D :
import { Rate, Button } from 'antd';

// Assets | ICONS :
import { AiFillLike } from 'react-icons/ai';

// API
import { ReviewPurchasedBooksAPI } from 'API/booking';

// CSS :
import "./Review.scss";
import { toast } from 'react-toastify';





const Review = ({ data, togglePopup, setReload }) => {

    const [formData, setFormData] = useState({
        text: "",
        value: null
    });
    const [loading, setLoading] = useState(false);


    const enteringData = (event) => {
        let { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value
        })

    };

    const saveReview = async () => {
        setLoading(true);

        let res = await ReviewPurchasedBooksAPI({ id: data?.bookData?._id, ...formData });
        if (res.error != null) {
            toast.error(res.error);
        } else {
            toast.success(res?.data?.message);
            togglePopup();
            setReload((preval) => !preval);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (data) {
            setFormData({
                text: data?.text || "",
                value: data?.value || null
            })
        } else {
            setFormData({
                text: "",
                value: null
            })
        }
    }, [])


    return (
        <>
            <div className="reviewModalContainer">
                <div className="header ">
                    <div className="likeThumb">
                        <AiFillLike size="4rem" color="#FFC01E" />
                    </div>
                </div>
                <div className="reviewOrder">Review Order</div>
                <div className="ratingStarts">
                    <Rate value={formData.value} onChange={(v) => enteringData({ target: { name: "value", value: v } })} className="rate" />
                </div>
                <div className="textAreaReview">
                    <textarea rows="3" placeholder="Write your Review" name='text' value={formData?.text} onChange={enteringData}></textarea>
                </div>
                <div className="submitBtn">

                    <Button className='dashboardBtn' style={{
                        width: "120px",
                        background: "#0a1c29",
                    }} onClick={saveReview} loading={loading}>Submit</Button>
                </div>
            </div>
        </>
    )
}

export default Review;