import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { Row, Col, Card, Select } from "antd";

// Assets | ICONS :
import { BsCreditCard2Front, BsTelephone } from "react-icons/bs";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { MdOutlineLocalShipping } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";
import { BiArrowBack } from "react-icons/bi";

// Components :
import Table from "../../../Users/Component/table/Table";

// API :
import { GetAllTransactionsAPI } from "../../../../../../API/transaction";
// Helpers :
import { toast } from "react-toastify";
import ImgURL from "Utils/ImgUrlGen";

// CSS :
import "./TransactionDetail.scss";





const TransactionDetail = ({ selectedTransaction, UserData, setSelectedUser, closePage, }) => {

  let TransactionType = selectedTransaction?.orderType




  const bookColumn = [
    {
      title: "Title",
      dataIndex: "productName",
      key: "productName",
      width: "200px",
      align: "left",
      ellipsis: true,
      render: (_, data) => `${data?.title}`
    },
    ...(UserData && UserData?.isSuperAdmin || UserData?.role?.name != "teacher" ? [
      {
        title: "Seller Name",
        dataIndex: "sellerName",
        key: "sellerName",
        align: "center",
        ellipsis: true,
        render: (_, data) =>
          `${data?.auther?.firstName} ${data?.auther?.lastName}`,
      },
      {
        title: "Seller Email",
        dataIndex: "sellerEmail",
        key: "sellerEmail",
        align: "center",
        ellipsis: true,
        render: (_, data) => `${data?.auther?.email}`,
      },
    ] : []),
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "150px",
      ellipsis: true,
      align: "center",
      render: (_, data) => <>${`${data?.price}`}</>,
    },
  ];
  const meetingColumn = [
    {
      title: "Title",
      dataIndex: "productName",
      key: "productName",
      width: "200px",
      align: "left",
      ellipsis: true,
      render: (_, data) => `${data?.title}`
    },
    ...(UserData && UserData?.isSuperAdmin || UserData?.role?.name != "teacher" ? [{
      title: "Host",
      dataIndex: "sallerName",
      key: "sallerName",
      align: "left",
      render: (_, data) => {
        return `${data?.admin?.firstName} ${data?.admin?.lastName}`
      },
      ellipsis: true,
    }] : []),
    ...(UserData && UserData?.isSuperAdmin || UserData?.role?.name != "student" ? [{
      title: "Student Name",
      dataIndex: "buryerName",
      key: "buryerName",
      align: "left",
      render: (_, data) =>
        `${data?.participants[0]?.firstName} ${data?.participants[0]?.lastName}`,
      ellipsis: true,
    }] : []),
    {
      title: "Price",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "150px",
      ellipsis: true,
      render: (_, data) => `${selectedTransaction?.orderPrice}`
    },
  ];

  return (
    <>
      <div className="TransactionContainer">
        <div className="heading">
          <BsArrowLeftShort className="icon" onClick={closePage} />
          {selectedTransaction ? "VIEW" : ""} TRANSACTION DETAILS
        </div>
        <Row gutter={26}>
          <Col xs={24} sm={24} md={16} lg={16} xl={19}>
            <div className="table">
              {
                TransactionType == "book" ?
                  <Table
                    rows={selectedTransaction?.sources}
                    columns={bookColumn}
                  />
                  :
                  <Table
                    rows={selectedTransaction?.sources}
                    columns={meetingColumn}
                  />
              }
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={5}>
            <div className="">
              <Card
                title="Payment Details"
                bordered={false}
                style={{
                  width: 300,
                  border: "1px solid var(--theme-color)",
                  borderRadius: "5px",
                  boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.1)",
                  marginTop: "50px",
                }}
              >
                <span className="creditCard">
                  <BsCreditCard2Front className="cardImg" />
                </span>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Order Total</p> <p>${selectedTransaction?.orderPrice} </p>
                </div>
                {
                  UserData?.role?.name == "teacher" ?
                    <>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p>Charges</p> <p>${selectedTransaction?.sellerCharges}</p>
                      </div>
                    </>
                    :
                    UserData?.role?.name == "student" ?
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <p>Charges</p> <p>${selectedTransaction?.buyerCharges}</p>
                        </div>
                      </>
                      :
                      <>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <p>Buyer Charges</p> <p>${selectedTransaction?.buyerCharges}</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <p>Seller Charges</p> <p>${selectedTransaction?.sellerCharges}</p>
                        </div>
                      </>
                }

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Total Amount</p> <p>${selectedTransaction?.balance}</p>
                </div>
                {
                  UserData?.isSuperAdmin &&
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p>Comission</p> <p>{selectedTransaction?.comissionPercent}%</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p>Comission Balance</p> <p>${selectedTransaction?.adminBalance}</p>
                    </div>
                  </>
                }
              </Card>
              {
                TransactionType == "book" &&
                <Card
                  title="Shipping Address"
                  bordered={true}
                  backgroundColor="yellow"
                  style={{
                    width: 300,
                    border: "1px solid var(--theme-color)",
                    borderRadius: "5px",
                    boxShadow: "3px 3px 3px 3px rgba(0, 0, 0, 0.1)",
                    marginTop: "40px",
                  }}
                >
                  <div>
                    <span className="creditCard">
                      <MdOutlineLocalShipping className="cardImg" />
                    </span>
                    <p className="shippingPhone">
                      <BsTelephone className="shippingIcon" />
                      {selectedTransaction?.shippingDetails?.contactNumber}
                    </p>
                    <p className="shippingPhone">
                      <TfiEmail className="shippingIcon" />
                      {selectedTransaction?.shippingDetails?.email}
                    </p>
                  </div>
                </Card>
              }
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TransactionDetail;