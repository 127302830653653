import React, { useRef, useState, useEffect } from "react";
// ANT-D | MUI :
import { Row, Col, Button, Avatar, Layout, Card, } from 'antd';
// Assets | ICONS :
import User from "./../../../../../Assets/Images/noImage.png"
import participants from 'Assets/Svgs/CallingIcons/participants.svg'
import screenShare from 'Assets/Svgs/CallingIcons/screenShare.svg'
import whiteboard from 'Assets/Svgs/CallingIcons/whiteboard.svg'
import { BsCameraVideoOff } from 'react-icons/bs'
import mic from 'Assets/Svgs/CallingIcons/mic.svg'
import CallIcon from 'Assets/Svgs/CallingIcons/call.svg'
import { VideoCameraOutlined, UserOutlined, AudioOutlined, AudioMutedOutlined } from '@ant-design/icons';
// Components :
import ParticipantsPanel from './Participants/Participants';
import ChatUI from './Chat/Chat';
// API :
import { StartPaidMeetingAPI } from "API/meeting";
// Redux :
import { useSelector } from "react-redux";
// WEB RTC :
import RTCClient, { RTC } from "RTC"
// CSS :
import "./newvideoplayer.scss";
import "./VideoPlayer.scss"
import { Socket } from "Utils/Socket";
let audioTrack;
let videoTrack;
let screenTrack;





let client = RTCClient()
const { Footer, Sider, Content } = Layout;
const VideoPlayer = ({ meetingData }) => {

    let UserData = useSelector(state => state.userData);

    const [isAdmin, setIsAdmin] = useState(null);
    const [participantsData, setParticipantsData] = useState([]);
    const [showParticipants, setShowParticipants] = useState(false);
    const [showChats, setShowChats] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');


    const [userData, setUserData] = useState(null)
    const [clientData, setClientData] = useState(null)
    const [isJoined, setIsJoined] = useState(false);
    const [isVideoOn, setIsVideoOn] = useState(false);

    const [isScreenOn, setIsScreenOn] = useState(false);

    const [isAudioPubed, setIsAudioPubed] = useState(false);
    const [isVideoPubed, setIsVideoPubed] = useState(false);

    const [isVideoSubed, setIsVideoSubed] = useState(false);
    const [isAudioSubed, setIsAudioSubed] = useState(false);

    const toggleParticipants = () => {
        setShowChats(false)
        setShowParticipants(!showParticipants);
    };
    const fullScreen = () => {
        let VideoPlayer = document.getElementById("remote-video")
        VideoPlayer.requestFullscreen()
    };
    const leaveChannel = async () => {
        setIsJoined(false);
        setIsAudioPubed(false);
        setIsVideoPubed(false);

        await client.leave();
        await StartPaidMeetingAPI(meetingData?._id)
        Socket.emit("disconnectMeetingRoom", meetingData?._id);
        window.location.href = "/dashboard/meetings"
    };

    const onUserPublish = async (user, mediaType) => {
        if (mediaType === "video") {
            const remoteTrack = await client.subscribe(user, mediaType);
            remoteTrack.play("remote-video");
            setIsVideoSubed(true);
        }
        if (mediaType === "audio") {
            const remoteTrack = await client.subscribe(user, mediaType);
            remoteTrack.play();
            setIsAudioSubed(true);
        }
    };
    const onUserUnPublish = async (user, mediaType) => {
        if (mediaType === "video") {
            const remoteTrack = await client.unsubscribe(user, mediaType);
            setIsVideoSubed(false);
        }
        if (mediaType === "audio") {
            const remoteTrack = await client.unsubscribe(user, mediaType);
            setIsAudioSubed(false);
        }
    };
    const publishVideo = async () => {
        setIsVideoOn(true);
        if (screenTrack) {
            await client.unpublish(screenTrack);
        }

        videoTrack = await RTC.createCameraVideoTrack();
        videoTrack.play("camera-video");

        if (!isJoined) {
            await joinChannel();
        }
        await client.publish(videoTrack);
        setIsVideoPubed(true);
    };
    const unPublishVideo = async () => {
        setIsVideoOn(false);
        if (videoTrack) {
            videoTrack.setEnabled(false);
        }

        await client.unpublish(videoTrack);
        setIsVideoPubed(false);
    };
    const togglePublishVideo = (flag) => {
        if (flag) {
            publishVideo()
        } else {
            unPublishVideo()
        }
    }
    const publishScreenShare = async () => {
        setIsScreenOn(true);

        if (videoTrack) {
            await client.unpublish(videoTrack)
        }


        setIsVideoOn(true);
        setIsVideoPubed(true);

        screenTrack = await RTC.createScreenVideoTrack();
        screenTrack.play("camera-video");

        await client.publish(screenTrack)

    };

    const unPublishScreenShare = async () => {
        setIsScreenOn(false);
        setIsVideoOn(false);
        setIsVideoPubed(false);
        if (screenTrack) {
            screenTrack.setEnabled(false)
        }

        await client.unpublish(screenTrack);
    };
    const togglePublishScreenShare = (flag) => {
        if (flag) {
            publishScreenShare()
        } else {
            unPublishScreenShare()

        }
    }

    const publishAudio = async () => {
        audioTrack = await RTC.createMicrophoneAudioTrack();

        if (!isJoined) {
            await joinChannel();
        }

        await client.publish(audioTrack);
        setIsAudioPubed(true);
    };
    const unPublishAudio = async () => {

        if (audioTrack) {
            audioTrack.setEnabled(false);
        }

        await client.unpublish(audioTrack);
        setIsAudioPubed(false);
    };
    const togglePublishAudio = (flag) => {
        if (flag) {
            publishAudio()
        } else {
            unPublishAudio()
        }
    }
    const joinChannel = async () => {
        if (isJoined) {
            await leaveChannel();
        }

        client && client?.on("user-published", onUserPublish);
        client && client?.on("user-unpublished", onUserUnPublish)

        await client.join(
            "1b1399d5e3e34c3397421642602e1fe3",
            meetingData.roomId,
            null,
            null
        );
        setIsJoined(true);
    };

    const handleSend = () => {
        if (newMessage) {
            setMessages([...messages, { message: newMessage, sender: UserData?._id }]);
            setNewMessage('');
        }
        sendMessage()
    };

    useEffect(() => {
        setIsAdmin(meetingData?.admin)
        if (meetingData?.admin?._id == UserData?._id) {
            setUserData(meetingData?.admin)
            setClientData(meetingData?.participants[0])
        } else {
            setClientData(meetingData?.admin)
            setUserData(meetingData?.participants[0])
        }
        setParticipantsData(meetingData?.participants);
        joinChannel()

        return () => {
            leaveChannel()
        }

    }, [])

    const sendMessage = () => {
        Socket.emit("message", { MeetingID: meetingData?._id, UserId: UserData?._id, data: { message: newMessage, sender: UserData?._id } })
    }
    const creatingOrJoinRoom = () => {
        Socket.emit("joinRoom", { MeetingID: meetingData?._id, UserId: UserData?._id })

    }
    useEffect(() => {
        creatingOrJoinRoom()
    }, [Socket])
    useEffect(() => {
        Socket.on("message", ({ data }) => {
            console.log("------ MESSAGE --------", data);
            setMessages([
                ...messages,
                data
            ])
        })
        console.log("---------- SSSS ----------> ", Socket);
        // return () => {
        //     // Socket.emit("disconnectMeetingRoom", meetingData?._id);
        // };
    }, [Socket])

    const buttonsData = [
        { icon: screenShare, text: 'Screen Share', onClick: () => togglePublishScreenShare(!isScreenOn) },
        { icon: participants, text: 'Participants', onClick: toggleParticipants },
        { icon: whiteboard, text: 'White Board', onClick: fullScreen },
        { icon: CallIcon, text: 'Leave', onClick: leaveChannel },
    ];
    return (

        <>
            {isVideoSubed ? (
                <>
                    <div className="myVideoContainer">
                        <Layout>
                            <Layout>
                                <Content>
                                    <div className="myCallingContainer">
                                        <div className="myClientContainer">
                                            <div className="myClient-box">
                                                <video width="100%" height="100%" id="remote-video" hidden={!isVideoSubed}></video>
                                                {!isVideoSubed && (
                                                    <>
                                                        <div className="myAvatar">
                                                            {clientData?.profileImage ? (
                                                                <img src={clientData.profileImage?.url} alt="" />
                                                            ) : (
                                                                <img src={User} alt="" />
                                                            )}
                                                        </div>
                                                        <div className="myUser-name">
                                                            {clientData?.firstName} {clientData?.lastName}
                                                        </div>
                                                        <div className="myMic-icon">
                                                            <Button className="myUsericonButton" icon={isAudioPubed ? <AudioOutlined /> : <AudioMutedOutlined />} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="myUserContainer">
                                            <div className="myUser-box">
                                                <video width="100%" height="100%" id="camera-video" hidden={!isVideoOn}></video>
                                                {!isVideoOn && (
                                                    <>
                                                        <div className="myAvatar">
                                                            {userData?.profileImage ? (
                                                                <img src={userData.profileImage?.url} alt="" />
                                                            ) : (
                                                                <img src={User} alt="" />
                                                            )}
                                                        </div>
                                                        <div className="myUser-name">
                                                            {userData?.firstName} {userData?.lastName}
                                                        </div>
                                                    </>
                                                )}
                                                <div className="myVideo-icon">
                                                    <Button className="myUsericonButton" icon={isAudioPubed ? <AudioOutlined /> : <AudioMutedOutlined />} onClick={() => togglePublishAudio(!isAudioPubed)} />
                                                    <Button className="myUsericonButton" icon={isVideoOn ? <VideoCameraOutlined /> : <BsCameraVideoOff />} onClick={() => togglePublishVideo(!isVideoOn)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="myActionBar">
                                        <div className="myActionButtons">
                                            {buttonsData.map((button, index) => (
                                                <div key={index} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                    <Button className="myIconButton" onClick={button.onClick} danger={button.danger}>
                                                        <img src={button.icon} alt="Error" width={27} />
                                                    </Button>
                                                    <h6 style={{ paddingTop: '10px' }}>{button.text}</h6>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Content>
                            </Layout>
                            {showParticipants && (
                                <Sider width={350} theme="light">
                                    <div style={{ paddingTop: '6rem' }}>
                                        <div className="myUserContainer">
                                            <ParticipantsPanel meetingData={meetingData} />
                                        </div>
                                    </div>
                                </Sider>
                            )}
                            {/* {showChats && (
                                <Sider width={350} theme="light">
                                    <div style={{ paddingTop: '30px' }}>
                                        <div className="myUserContainer">
                                            <Card title="Chats" className="myParticipants-container" style={{ marginTop: '30px' }}>
                                                <ChatUI messages={messages} setMessages={setMessages} newMessage={newMessage} setNewMessage={setNewMessage} handleSend={handleSend} UserId={UserData?._id} />
                                            </Card>
                                        </div>
                                    </div>
                                </Sider>
                            )} */}
                        </Layout>
                    </div>

                </>
            ) :
                (
                    <>
                        <div className="videoContainer">
                            <Layout>
                                <Layout>
                                    <Content >
                                        <div className="CallingContainer">
                                            <div className='userContainer'>
                                                <div className='user-box'>
                                                    <video width="100%" height="100%" id="remote-video" hidden={!isVideoSubed}></video>
                                                    {!isVideoSubed &&
                                                        <>
                                                            <div className="avater">
                                                                {
                                                                    clientData?.profileImage ?
                                                                        <img src={clientData.profileImage?.url} alt="" />
                                                                        :
                                                                        <img src={User} alt="" />
                                                                }
                                                            </div>
                                                            <div className="user-name">
                                                                {clientData?.firstName} {clientData?.lastName}
                                                            </div>

                                                            <div className="mic-icon">
                                                                <Button className='usericonButton' icon={isAudioPubed ? <AudioOutlined /> : <AudioMutedOutlined />} />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className='user-box'>
                                                    <video width="100%" height="100%" id="camera-video" hidden={!isVideoOn}></video>
                                                    {!isVideoOn &&
                                                        <>
                                                            <div className="avater">
                                                                {
                                                                    userData?.profileImage ?
                                                                        <img src={userData.profileImage?.url} alt="" />
                                                                        :
                                                                        <img src={User} alt="" />

                                                                }
                                                            </div>
                                                            <div className="user-name">
                                                                {userData?.firstName} {userData?.lastName}
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="video-icon">
                                                        <Button className='usericonButton' icon={isAudioPubed ? <AudioOutlined /> : <AudioMutedOutlined />} onClick={() => togglePublishAudio(!isAudioPubed)} />
                                                        <Button className='usericonButton' icon={isVideoOn ? <VideoCameraOutlined /> : <BsCameraVideoOff />} onClick={() => togglePublishVideo(!isVideoOn)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='actionBar'>
                                                <div className='actionButtons'>
                                                    {buttonsData.map((button, index) => (
                                                        <div key={index} style={{ textAlign: 'center', cursor: "pointer" }}>
                                                            <Button className='iconButton' onClick={button.onClick} danger={button.danger}> <img src={button.icon} alt="Error" width={27} /></Button>
                                                            <h6 style={{ paddingTop: '10px' }}>{button.text}</h6>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Content>
                                </Layout>
                                {showParticipants && (
                                    <Sider width={350} theme='light'> <div style={{ paddingTop: '6rem' }}>
                                        <div className='userContainer'>
                                            <ParticipantsPanel meetingData={meetingData} />
                                        </div>
                                    </div>
                                    </Sider>
                                )}
                                {showChats && (
                                    <Sider width={350} theme='light'> <div style={{ paddingTop: '30px' }}>
                                        <div className='userContainer'>
                                            <Card title="chats" className="participants-container" style={{ marginTop: '30px' }}>
                                                <ChatUI messages={messages} setMessages={setMessages} newMessage={newMessage} setNewMessage={setNewMessage} handleSend={handleSend} UserId={UserData?._id} />
                                            </Card>
                                        </div>
                                    </div>
                                    </Sider>
                                )}
                            </Layout>
                        </div>
                    </>
                )
            }
        </>


    );
}

export default VideoPlayer;