import React from 'react'

// Chart.Js : 
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from "react-chartjs-2";

// CSS :
import "./DonutChart.scss"
import { useState } from 'react';
import { useEffect } from 'react';





// Registring Chart :
ChartJS.register(ArcElement, Tooltip, Legend);

// Sample Chart Data :
const Sample = {
    labels: [],
    datasets: [
        {
            label: ["Students"],
            data: [40, 60],
            backgroundColor: ["#006400", "#FCD117"],
            borderWidth: 4
        },
    ]
};
////////////////////////////////////////////////////////////////////////



const DonutChart = ({ title, staticsData }) => {
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: ["Students", "Teachers"],
                data: [1, 1],
                backgroundColor: ["#006400", "#FCD117"],
                borderWidth: 4
            },
        ]
    })

    useEffect(() => {
        if (staticsData) {
            setData({
                labels: [],
                datasets: [
                    {
                        label: ["Students", "Teachers"],
                        data: [staticsData?.students || 1, staticsData?.teachers || 1],
                        backgroundColor: ["#006400", "#FCD117"],
                        borderWidth: 4
                    },
                ]
            })
        }
    }, [staticsData])
    return (
        <>
            <div className="donutchart_container">
                <div className='chart'>
                    <Pie data={data} />
                </div>
                <div className='status_box'>
                    <div className='status'>
                        <div className='dot' style={{ backgroundColor: `${data.datasets[0]["backgroundColor"][0]}` }} > </div>
                        <div className='dot_title'>
                            Teachers
                        </div>
                    </div>
                    <div className='status'>
                        <div className='dot' style={{ backgroundColor: `${data.datasets[0]["backgroundColor"][1]}` }}> </div>
                        <div className='dot_title'>
                            Students
                        </div>
                    </div>
                    {/* <div className='status'>
                        <div className='dot' style={{ backgroundColor: `${data.datasets[0]["backgroundColor"][2]}` }}> </div>
                        <div className='dot_title'>
                            Views
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default DonutChart