import axios from "../AxiosInstance";

// Helper :
import AuthTokenGen from "../Utils/AuthTokenGen"


const GetAllWithdrawalAPI = async (type = null) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: "/withdrawal",
            method: "GET",
            headers: AuthTokenGen()
        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}
const TotalWithdrawalcurrentUserAPI = async (type = null) => {
    let resolved = {
        error: null,
        data: null,
    }
    try {
        let res = await axios ({
            url: "/withdrawal/totalWithdrawal",
            method: "GET",
            headers: AuthTokenGen()
        })
        resolved.data = res.data
    }
        catch(err) {
            if (err && err.response && err?.response?.data?.message) {
                resolved.error = err.response.data.message
            } else {
                resolved.error = "Something went Wrong"
            }
            
        }
    return resolved;
}
const addWithdrawalReq = async (formData) => {
    let resolved = {
        error: null,
        data: null,
    }
    try {
        let res = await axios ({
            url: "/withdrawal",
            method: "POST",
            data: formData,
            headers: AuthTokenGen()
        })
        resolved.data = res.data
    }
        catch(err) {
            if (err && err.response && err?.response?.data?.message) {
                resolved.error = err.response.data.message
            } else {
                resolved.error = "Something went Wrong"
            }
            
        }
    return resolved;
}
const updateWithdrawalAPI = async(id, formData) => {
    // console.log(formData.get("file"));
    let resolved = {
        error: null,
        data: null
    }
    try {
        let res = await axios ({
            url: `/withdrawal/updateWithdrawal/${id}`,
            method: "POST",
            data: formData,
            headers: AuthTokenGen()
        })
        resolved.data = res.data
        
    }catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}

export {GetAllWithdrawalAPI, TotalWithdrawalcurrentUserAPI, addWithdrawalReq, updateWithdrawalAPI , };