import React, { useState } from "react";

// MUI | ANT-D :
import { Button, Input, Select, Upload, Radio, DatePicker, Space, Popconfirm, message, Tooltip } from "antd";
// Rsuite Date Piker
// import { DatePicker } from 'rsuite';


// Assets | ICONS :
import { BsArrowLeftShort, BsDownload } from "react-icons/bs";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { RiInformationLine } from 'react-icons/ri';


import { Calendar, Chart, MedalStar, VideoTime } from "iconsax-react";
import { LocalDiningOutlined } from "@mui/icons-material";
import educationHistory from "Assets/Svgs/educationHistory.svg";
import Degree from "Assets/Svgs/Degree.svg";
import Institude from "Assets/Svgs/Institude.svg";
import { Book, DocumentUpload, DollarSquare, Subtitle, Category, Tag, Eye } from 'iconsax-react';
import EditIcon from "../../../../../../../Assets/Images/editIcon.png";
import BagIcon from "../../../../../../../Assets/Images/bagIcon.png";


// Components :
import Table from "../../table/Table";

// APIs :
import { AddEducationAPI } from "API/user";
import { DeleteUserEducationAPI } from "API/user";
import { EditEducationAPI } from "API/user";
// Redux :
import { useDispatch } from "react-redux";
import { refreshAPIsActions } from "Redux/Slice/refreshAPIs";
// Helpers :
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import ConfirmationModel from '../../../../../../../Components/ConfirmationModel/ConfirmationModel';


// CSS :
import "./EducationHistory.scss";



const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    return console.log("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    return console.log("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const EducationHistory = ({ selectedUser }) => {

  let Dispatch = useDispatch();


  const [toggleModel, setToggleModel] = useState("all");
  const [currentEducationId, setCurrentEducationId] = useState(null);


  const [formData, setFormData] = useState({
    educationType: "",
    degreeType: null,
    degreeTitle: "",
    instituteName: "",
    specialty: "",
    startDate: null,
    endDate: null,
    duration: null,
    progress: "",
    education: [],
    educationId: currentEducationId
  });

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const allowedFileTypes = ['.doc', '.docx', '.pdf'];
  const [editMode, setEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState({});




  const onStartChange = (date, dateString) => {
    handleData("startDate", dateString);
  };
  const onEndChange = (date, dateString) => {
    handleData("endDate", dateString);
  };
  const onTimeChange = (time, timeString) => {
    handleData("duration", timeString);
  };

  const handleData = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value ? "" : "This field is required",
    });
  };

  const handleFormData = (event) => {
    let { name, value } = event.target;
    handleData(name, value);
  };

  const handlePreview = (e) => {
    e.preventDefault();
    if (!file?.Location) {
      window.open(URL.createObjectURL(file))
    }
    else {
      window.open(file.Location)
    }
  }

  const handleSelectChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    const requiredFields = ["specialty", "instituteName", "degreeTitle", "degreeTitle", "degreeType", "startDate", "endDate", "duration", "progress", "education"];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        errors[field] = "This field is required";
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUploadChange = (info) => {
    setFile(info?.file?.originFileObj || null);
  };


  const educationFunc = async () => {
    if (!validateForm()) {
      toast.error("Some fields are Missing");

      return;
    }
    setLoading(true);
    let fData = new FormData();
    Object.keys(formData).map((key) => {
      if (formData[key]) {
        fData.append(key, formData[key]);
      }
    });
    if (file) {
      fData.append("file", file);
    }
    let res;
    if (editMode) {
      // append formData with currentEducationId
      fData.append("educationId", currentEducationId);

      // Call update API here
      res = await EditEducationAPI(selectedUser?._id, formData);
    } else {
      res = await AddEducationAPI(selectedUser?._id, fData);
    } if (res?.error != null) {
      toast.error(res.error);
    } else {
      toast.success(res?.data?.message);
      selectedUser.education = res?.data?.result?.education;
      setFormData({
        educationType: "",
        degreeType: "",
        degreeTitle: "",
        instituteName: "",
        specialty: "",
        startDate: null,
        endDate: null,
        duration: null,
        progress: "",
        education: [],
      })
      setFile(null);
      setToggleModel("all");
      Dispatch(refreshAPIsActions.setRefreshUserData())
    }

    setLoading(false);
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    roleID: null,
    loading: false
  })

  const handleDeleteRoleConfirmation = (role) => {
    setDeleteConfirmation({
      open: true,
      roleID: selectedUser?._id,
      educationId: role?._id,
      loading: false
    })
  }

  const handleDeleteRole = async () => {
    setDeleteConfirmation({
      ...deleteConfirmation,
      loading: true
    })

    let res = await DeleteUserEducationAPI(deleteConfirmation?.roleID, deleteConfirmation?.educationId)
    if (res.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res.data?.message)
    }
    setDeleteConfirmation({
      open: false,
      roleID: null,
      loading: false
    })
  }
  const handleNotDeleteRole = () => {
    setDeleteConfirmation({
      open: false,
      roleID: null,
      loading: false
    })
  }

  const handleEdit = (education) => {
    const educationid = education._id
    setCurrentEducationId(education._id);

    setFormData({
      educationType: education.educationType,
      degreeType: education.degreeType,
      degreeTitle: education.degreeTitle,
      instituteName: education.instituteName,
      specialty: education.specialty,
      startDate: education.startDate,
      endDate: education.endDate,
      duration: education.duration,
      progress: education.progress,
      education: education.education,
      image: education.image,
      educationId: educationid,
      file: setFile(education.image || null),

    });
    setToggleModel("add");
    setEditMode(true);
  };
  const edit = <span>Edit</span>;
  const remove = <span>Delete</span>;

  const columns = [
    {
      title: "Education Type",
      dataIndex: "educationType",
      key: "educationType",
      width: "10%",
    },
    {
      title: "Degree Type",
      dataIndex: "degreeType",
      key: "degreeType",
      width: "15%",
    },
    {
      title: "Degree Title",
      dataIndex: "degreeTitle",
      key: "address",
      width: "15%",
    },
    {
      title: "Institute Name",
      dataIndex: "instituteName",
      key: "instituteName",
      width: "15%",
    },
    {
      title: "Specialty",
      dataIndex: "specialty",
      key: "specialty",
      width: "15%",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "15%",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "20%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "20%",
    },
    {
      title: "Still In Progress",
      dataIndex: "progress",
      key: "progress",
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      align: "center",
      render: (_, data) => data?.id != 1 && <>
        <div className="actionBox">
          {
            // (UserData?.isSuperAdmin || RoutePermissions?.includes("edit")) &&
            <Tooltip placement="top" title={edit}>
              <div className="actionBtn" onClick={() => handleEdit(data)}  >
                <img src={EditIcon} alt="" className='icon cursor' />
              </div>
            </Tooltip>
          }
          {
            // (UserData?.isSuperAdmin || RoutePermissions?.includes("delete")) &&

            <Tooltip placement="top" title={remove}>
              <div className="actionBtn" onClick={() => handleDeleteRoleConfirmation(data)}
              >
                <img src={BagIcon} alt="" className='icon cursor' />
              </div>
            </Tooltip>
          }
        </div>
      </>

    },
  ];




  const shortenHeading = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  return (
    <>
      <div className="EducationHistoryMainConatiner">
        {
          toggleModel == "all" ?
            <div className="allEducationContainer">
              <div className="flexLineSpace">
                <div className="heading upper">
                  Education Records
                </div>
                <div className="buttonandFilter">
                  <Button className='dashboardBtn' style={{
                    width: "120px",
                    background: "#0a1c29",
                  }} onClick={() => setToggleModel("add")}> Add Record </Button>
                </div>
              </div>
              <div className="table">
                <Table columns={columns} rows={selectedUser?.education} />
              </div>
              <ConfirmationModel open={deleteConfirmation.open} onOk={handleDeleteRole} onCancel={handleNotDeleteRole} confirmLoading={deleteConfirmation.loading}>
                <div className="deleteModel">
                  <div className="titleBox">
                    <RiInformationLine className='icon' /> <div className="title"> Are you sure you want to delete this Record? </div>
                  </div>
                </div>
              </ConfirmationModel>
            </div>
            :
            <div className="addEductionContainer">
              <div className="EducationHistoryHeading">
                <div className="eduDiv">
                  <BsArrowLeftShort className='icon' onClick={() => setToggleModel("all")} />
                  <img src={educationHistory} alt="educationHistory" />
                  <h1 className="heading">EDUCATION HISTORY</h1>
                </div>
              </div>
              <div className="InputFields">
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="educationField eduInput">
                      <h1 className="TypeHeading">Education Type</h1>
                      <div>
                        <Radio.Group
                          name="educationType"
                          onChange={handleFormData}
                          value={formData.educationType}
                        >
                          <Radio value="Islamic">Islamic</Radio>
                          <Radio value="other">Other</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Inputfield">
                  <div className="field1 field">

                    <div className="field1 field" id="gender">
                      <div className="lableName">Degree Type</div>
                      <div>


                        <img className="degreeImage2" src={Degree} alt="Degree" />
                        <Select
                          placeholder="Degree Type"
                          className="registerSelector"
                          value={formData.degreeType}
                          getPopupContainer={() => document.getElementById('gender')}

                          onChange={(value) => handleSelectChange("degreeType", value)}
                          options={[
                            { value: "diploma", label: "Diploma" },
                            { value: "bachelors", label: "Bachelors" },
                            { value: "others", label: "Others" },
                          ]}
                        />

                      </div>
                      {formErrors.degreeType && <p className="errorMessage">{formErrors.degreeType}</p>}

                    </div>
                  </div>
                  <div className="field2 field"><div className="field2 field" >
                    <div className="lableName">Degree Title</div>
                    <img className="degreeImage2" src={Degree} alt="Degree" />
                    <Input
                      size="large"
                      className="eduInput"
                      type="text"
                      placeholder="Degree Title"
                      name="degreeTitle"
                      value={formData?.degreeTitle}
                      onChange={handleFormData}
                    />


                  </div>{formErrors.degreeTitle && <p className="errorMessage">{formErrors.degreeTitle}</p>}</div>

                </div>
                <div className="Inputfield">
                  <div className="field1 field"> <div className="field1 field">
                    <div className="lableName">Institude Name</div>
                    <img className="degreeImage2" src={Institude} alt="Degree" />
                    <Input
                      size="large"
                      className="InsInput"
                      type="text"
                      placeholder="Institute Name"
                      name="instituteName"
                      value={formData?.instituteName}
                      onChange={handleFormData}
                    />


                  </div>  {formErrors.instituteName && <p className="errorMessage">{formErrors.instituteName}</p>}</div>

                  <div className="field2 field">
                    <div className="lableName">Speciality</div>
                    <Input
                      prefix={<MedalStar />}
                      size="large"
                      className="eduInput"
                      type="text"
                      placeholder="Specialty"
                      value={formData?.specialty}
                      name="specialty"
                      onChange={handleFormData}
                    />
                    {formErrors.specialty && <p className="errorMessage">{formErrors.specialty}</p>}

                  </div>
                </div>
                <div className="Inputfield">
                  <div className="field1 field">    <div className="field1 field">
                    <div className="lableName">Start Date</div>
                    <Calendar className="degreeImage2" />
                    <DatePicker
                      size="large"
                      className="eduInput"
                      type="date"
                      placeholder="Select Start Date"
                      name="startDate"
                      onChange={onStartChange}
                      // format={"DD-MM-YYYY"}
                      value={formData?.startDate ? dayjs(formData?.startDate) : null}
                    />
                  </div>                    {formErrors.startDate && <p className="errorMessage">{formErrors.startDate}</p>}
                  </div>

                  <div className="field2 field">
                    <div className="field2 field">
                      <div className="lableName">End Date</div>
                      <Calendar className="degreeImage2" />
                      <DatePicker
                        size="large"
                        className="eduInput"
                        type="date"
                        placeholder="Select End Date"
                        name="endDate"
                        value={formData?.endDate ? dayjs(formData?.endDate) : null}
                        onChange={onEndChange}
                      // format={"DD-MM-YYYY"}
                      />
                    </div>
                    {formErrors.endDate && <p className="errorMessage">{formErrors.endDate}</p>}
                  </div>

                </div>
                <div className="Inputfield">
                  <div className="field1 field">
                    <div className="field1 field">
                      <div className="lableName">Duration in Year</div>
                      <VideoTime className="degreeImage2" />
                      <Input
                        size="large"
                        className="eduInput"
                        type="text"
                        placeholder="Duration in Years"
                        name="duration"
                        value={formData?.duration}
                        onChange={handleFormData}
                      />
                    </div>
                    {formErrors.duration && <p className="errorMessage">{formErrors.duration}</p>}

                  </div>
                  <div className="field2 field">
                    <div className="lableName">Still in Progress</div>
                    <div className="progressInput">
                      {<Chart />}
                      <h1 className="progressHeading ">Still In Progress</h1>
                      <div>
                        <Radio.Group
                          name="progress"
                          onChange={handleFormData}
                          defaultValue={formData.progress}
                          className="progressRadio"
                          value={formData?.progress}
                        >
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>

                      </div>

                    </div>
                    {formErrors.progress && <p className="errorMessage">{formErrors.progress}</p>}

                  </div>
                </div>

                <div className="Inputfield">
                  {/* <div className="field2 field">
                    <div className="attachment uploadBtn">
                      <div className="icon">
                        <BsDownload style={{ color: "black" }} /> {file ? shortenHeading(file?.name, 50) : "Attach File"}
                      </div>
                      {file && <div className="iconEye" onClick={handlePreview}><Eye size={26} color="#000" /></div>}
                      <Upload
                        name="image"
                        className="upload"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleUploadChange}
                      >
                      </Upload>
                    </div> */}

                  <div className="field2  field3 field">
                    <div className="labelName" style={{ marginBottom: '1%' }}>Upload Documents</div>

                    <div className="uploadBtn">
                      <div className="icon">
                        <BsDownload style={{ color: "black" }} />
                        {file?.name ? shortenHeading(file.name, 50) : (file?.Location ? file.Location : "Attach File")}
                      </div>

                      {file && (
                        <div className="iconEye" onClick={handlePreview}>
                          <Eye size={26} color="#000" />
                        </div>
                      )}

                      <Upload
                        value={formData?.image}

                        name="image"
                        className="upload"
                        showUploadList={false}
                        accept={allowedFileTypes.join(',')}
                        onChange={handleUploadChange}
                      >
                        <DocumentUpload />
                      </Upload>

                    </div>
                  </div>



                  <div className="saveButton">
                    <Button className="yellowGraBtn btn" loading={loading} onClick={educationFunc}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default EducationHistory;