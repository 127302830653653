import React, { useState, useEffect } from 'react'

// MUI | ANT-D :
import { Input, Button, Image } from 'antd';

// Components
import Infomation from './Components/InformationBox/Information';

// Assets | ICONS :
import { ReactComponent as Save } from '../../../../../../Assets/Post/save.svg'
import Avater from '../../../../../../Assets/Images/profile.jpg'
import { BiArrowBack } from 'react-icons/bi';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PendingActionsIcon from '@mui/icons-material/PendingActions';


// API :
import { CreatBlogsAPI, UpdateBlogsAPI, ApproveBlogsAPI } from '../../../../../../API/blogs';

// Helpres :
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import ImgURLGEN from 'Utils/ImgUrlGen';

// CSS :
import "./ReviewBlog.scss"
import 'react-quill/dist/quill.bubble.css';





const save = (
    <div className='antPopover'>
        <div className="popoverItem">
            <Save className='icon' />
            As New Post
        </div>
        <div className="popoverItem">
            <Save className='icon' />
            As Draft
        </div>
        <div className="popoverItem">
            <Save className='icon' />
            Export to PDF
        </div>
        <div className="popoverItem">
            <Save className='icon' />
            Export to Word
        </div>

    </div>
);

const Blog = ({ allBlogs, selectedBlog, closeSubPage }) => {

    const [postData, setPostData] = useState({
        title: "",
        quote: "",
        detail: "",
        file: null,
        slug: "",
        tags: [],
        categories: []
    })
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false)


    const handleReviewAcceptBlog = async () => {
        let data = {
            blogId: selectedBlog?._id,
            status: "approved"
        }
        const res = await ApproveBlogsAPI(data)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res?.data?.message)
        }
        closeSubPage()
        setLoading(false)
    }
    const handleReviewPendingBlog = async () => {
        let data = {
            blogId: selectedBlog?._id,
            status: "Pending"
        }
        const res = await ApproveBlogsAPI(data)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res?.data?.message)
        }
        closeSubPage()
        setLoading(false)
    }
    const handleReviewRejectBlog = async () => {
        let data = {
            blogId: selectedBlog?._id,
            status: "rejected"
        }
        const res = await ApproveBlogsAPI(data)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res?.data?.message)
        }
        closeSubPage()
        setLoading(false)
    }

    useEffect(() => {
        if (selectedBlog) {
            const findBlog = allBlogs.find(val => val?._id == selectedBlog?._id)
            if (findBlog) {
                setPostData({
                    title: findBlog?.title,
                    quote: findBlog?.quote,
                    detail: findBlog?.detail,
                    file: null,
                    slug: findBlog?.slug,
                    tags: findBlog?.tags || [],
                    categories: findBlog?.categories || []
                })
                setImageUrl(findBlog?.image?.url && ImgURLGEN(findBlog?.image))
            }
        } else {
            setPostData({
                title: "",
                quote: "",
                detail: "",
                file: null,
                slug: "",
                tags: [],
                categories: []
            })
            setImageUrl()
        }
    }, [selectedBlog])

    return (
        <>
            <div className='reviewBlogContainer'>
                <div className="flexLineSpace">
                    <div className="heading upper flexLine HeaderLeftReviewPage">
                        <BiArrowBack className='icon cursor' onClick={closeSubPage} />
                        <div className="heading">Review Blogs</div>

                    </div>
                    <div className="flexLine HeaderRightReviewPage">
                        <Button className='greenGradientBtn commonReviewBtn' onClick={handleReviewAcceptBlog}> <DoneIcon className='commonIconReviewPage' />Approve </Button>
                        {/* <Button className='yellowGradientBtn commonReviewBtn' onClick={handleReviewPendingBlog}  > <PendingActionsIcon className='commonIconReviewPage'></PendingActionsIcon>Pending</Button> */}

                        <Button className='redGradientBtn commonReviewBtn' onClick={handleReviewRejectBlog} style={{ backgroundColor: "var(--danger)" }}> <CloseIcon className='commonIconReviewPage' /> Reject </Button>
                    </div>
                </div>
                <div className="box">
                    <div className="editor-section">
                        <div className="reviewImage">
                            <Image
                                // width={150}
                                className='ReviewImageHead'
                                src={ImgURLGEN(selectedBlog?.image)}
                            />
                        </div>
                        <div className="contentAreaReviewPage">
                            <div className="flexLine">
                                <div className="inputBox">
                                    <div className="title">Title</div>
                                    <Input disabled size="large" placeholder="Title" value={postData.title} name="title" />
                                </div>
                                <div className="inputBox">
                                    <div className="title">Slug</div>
                                    <Input disabled size="large" placeholder="Slug" value={postData.slug} name="slug" />
                                </div>
                            </div>
                            <div className="flexLine">
                                <div className="inputBox">
                                    <div className="title">Quote</div>
                                    <Input disabled size="large" placeholder="Quote" value={postData.quote} name="quote" />
                                </div>
                            </div>

                            <div className="flexLine" style={{ marginBottom: "3rem" }}>
                                <div className="inputBox">
                                    <div className="title">Content</div>
                                    <ReactQuill style={{ height: "250px" }} theme='bubble' className='contentPara' value={postData?.detail} readOnly onChange={null} />
                                </div>
                            </div>
                            <div className="flexLine">
                                <div className="inputBox">
                                    {/* <div className="title">Author</div> */}
                                    <div className="profileBox">
                                        <div className="avater">
                                            <img src={ImgURLGEN(selectedBlog?.auther?.profileImage)} alt="Error" />
                                        </div>
                                        <div className="details ">
                                            <div className="name">{selectedBlog?.auther?.firstName} {selectedBlog?.auther?.lastName}</div>
                                            <div className="bio">{selectedBlog?.auther?.bio}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="info-section">
                        <Infomation postData={postData} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog
