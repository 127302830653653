import React, { useState } from 'react'

// MUI | ANT-D :
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { CircularProgress } from '@mui/material';
import { Button, Input, Skeleton } from 'antd';

// ICONS | Assets :
import { RiInformationLine } from "react-icons/ri";
import { BagCross, Edit2 } from "iconsax-react"
import cardImg from "../../../../../../Assets/Images/card-img.jpg";

// Components :
import ConfirmationModel from 'Components/ConfirmationModel/ConfirmationModel';
// APIs :
import { DeleteEventAPI } from "API/event"
// Redux :
import { useSelector } from 'react-redux';
// Helpers :
import { toast } from 'react-toastify';

// CSS :
import "./AllEvents.scss";
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';
import ImgURLGEN from 'Utils/ImgUrlGen';




const AllEvents = ({ allEvents, loading, togglePage, RoutePermissions, setReload }) => {

    let UserData = useSelector(state => state.userData)

    const [deleteConfirmation, setDeleteConfirmation] = useState({
        open: false,
        eventID: null,
        loading: false
    })


    const shortenHeading = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        } else {
            return text;
        }
    };
    const shortenDescription = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        } else {
            return text;
        }
    };

    const handleDeleteEventConfirmation = (event) => {
        setDeleteConfirmation({
            open: true,
            eventID: event?._id,
            loading: false
        })
    }
    const handleDeleteEvent = async () => {
        setDeleteConfirmation({
            ...deleteConfirmation,
            loading: true
        })
        let res = await DeleteEventAPI(deleteConfirmation.eventID)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
        }
        setDeleteConfirmation({
            open: false,
            eventID: null,
            loading: false
        })
        setReload((preVal) => !preVal)
    }
    const handleNotDeleteEvent = () => {
        setDeleteConfirmation({
            open: false,
            eventID: null,
            loading: false
        })
    }


    return (
        <>
            <div className="allEventsBox">
                <div className="flexLineSpace">
                    <div className="heading upper customheadingevents">
                        All Events
                    </div>
                    <div className="buttonandFilter">
                        {
                            (UserData?.isSuperAdmin || RoutePermissions?.includes("create")) &&
                            <Button className='dashboardBtn' style={{
                                width: "120px",
                                background: "#0a1c29",
                            }} onClick={() => togglePage(null)}> Add Event </Button>
                        }
                    </div>
                </div>

                <div className="ViewBodyMain">
                    {/* <div className="Events"> */}
                    {
                        loading ?
                            <div className="skeleton">
                                <Skeleton.Button className="skel" variant="" active={true} />
                                <Skeleton.Button className="skel" variant="" active={true} />
                                <Skeleton.Button className="skel" variant="" active={true} />
                                <Skeleton.Button className="skel" variant="" active={true} />
                                <Skeleton.Button className="skel" variant="" active={true} />
                                <Skeleton.Button className="skel" variant="" active={true} />
                            </div>

                            : allEvents && allEvents.length >= 1 ?
                                allEvents?.map((data, i) => {
                                    return (
                                        <>

                                            {/* <div className="cards">
                                                <div className="card">
                                                    <div className="img">
                                                        <img src={cardImg} alt="" />
                                                        <div className="badge">
                                                            <span>25</span>
                                                            <span>Nov</span>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="heading">Learn About Hajj</div>
                                                        <div className="bio">
                                                            <div className="time"><AiOutlineClockCircle className='icon' /> 8.00 - 5.00</div>
                                                            <div className="time"><MdLocationPin className='icon' /> NewYork City</div>
                                                        </div>
                                                        <div className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="img">
                                                        <img src={cardImg} alt="" />
                                                        <div className="badge">
                                                            <span>25</span>
                                                            <span>Nov</span>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="heading">Learn About Hajj</div>
                                                        <div className="bio">
                                                            <div className="time"><AiOutlineClockCircle className='icon' /> 8.00 - 5.00</div>
                                                            <div className="time"><MdLocationPin className='icon' /> NewYork City</div>
                                                        </div>
                                                        <div className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                                                    </div>
                                                </div>


                                            </div> */}

                                            <div className="blog" key={i}>
                                                <div className='blogImage'>
                                                    <div className="img">
                                                        <img src={ImgURLGEN(data?.image)} alt="ERROR" />
                                                        <div className="badge">
                                                            {new Date(data?.createdAt).toLocaleDateString('en-US', {
                                                                day: 'numeric',
                                                                month: 'short',
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="details">
                                                    <div className="title">{shortenHeading(data?.title, 52)}</div>
                                                    <div className="content">{shortenDescription(data?.description, 180)}</div>
                                                </div>
                                                <div className="blogButtons">
                                                    {
                                                        UserData?._id == data?.user?._id ?
                                                            <>
                                                                {
                                                                    (UserData?.isSuperAdmin || RoutePermissions?.includes("edit")) &&
                                                                    <Button className="greenGradientBtn" onClick={() => togglePage(data)} ><Edit2 className='CommonBtnBlog' />Edit</Button>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (UserData?.isSuperAdmin || RoutePermissions?.includes("edit")) &&
                                                                    <Button className="greenGradientBtn" onClick={() => togglePage(data)} ><Edit2 className='CommonBtnBlog' />Edit</Button>
                                                                }
                                                            </>
                                                    }
                                                    {
                                                        (UserData?.isSuperAdmin || RoutePermissions?.includes("delete")) &&
                                                        <Button className="redGradientBtn" onClick={() => handleDeleteEventConfirmation(data)} ><BagCross className='CommonBtnBlog' />Delete</Button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                :
                                <>
                                    No Event Found
                                </>
                    }
                    {/* </div> */}
                </div>
            </div>
            <ConfirmationModel open={deleteConfirmation.open} onOk={handleDeleteEvent} onCancel={handleNotDeleteEvent} confirmLoading={deleteConfirmation.loading}>
                <div className="deleteModel">
                    <div className="titleBox">
                        <RiInformationLine className='icon' /> <div className="title"> Are you sure you want to delete this Event? </div>
                    </div>
                </div>
            </ConfirmationModel>
        </>
    )
}

export default AllEvents