import React from 'react'
import { CircularProgress } from '@mui/material'
import './styles.scss'

const PrimaryButton = ({ primaryFun, heading, loading }) => {
    return (
        <>
            <div className='primary-button'>
                <button className="pri-btn" onClick={loading ? null : primaryFun}>
                    <span className='click'> {loading ? <div className='loading'> <CircularProgress size={"1.5rem"} style={{ color: "black" }} /> </div> : " Click!"}</span><span>{heading} </span>
                </button>
            </div>
        </>
    )
}

export default PrimaryButton
