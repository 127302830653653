import React, { useEffect, useState } from 'react';

// css
import './DetailWithdrawal.scss'
// Redux :
import { useSelector } from 'react-redux';

// Assets
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Button, Input, Upload, Select, Image } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { updateWithdrawalAPI } from 'API/withdrawal';
import { toast } from 'react-toastify';
import ImgURLGEN from 'Utils/ImgUrlGen';
import ReactQuill from 'react-quill';


const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        return console.log('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        return console.log('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const DetailWithdrawal = ({ setCurrentPage, closePage, selectedWithdrawal, loading }) => {
    const UserData = useSelector(state => state?.userData)
    const data = selectedWithdrawal;
    const [visible, setVisible] = useState(false);
    const [scaleStep, setScaleStep] = useState(0.5);


    const [imageUrl, setImageUrl] = useState(null)
    const [file, setFile] = useState(null)

    useEffect(() => {

        if (data.status) {
            setFormData({
                status: data.status,
            })
        }
        else {
            setFormData({
                status: "",
            })
        }

        if (data?.image) {
            setImageUrl(ImgURLGEN(data?.image))
        }
        else {
            setImageUrl()
        }


    }, [data])

    // const [selectedStatus, setSelectedStatus] = useState("")
    const [formData, setFormData] = useState({
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        currency: data?.currency,
        country: data?.country,
        branch_code: data?.branch_code,
        iban: data?.iban,
        account_number: data?.account_number,
        amount: data?.amount,
        image: null,
        status: "",
    })
    const [formError, setFormError] = useState({
        image: null,
        status: null
    })

    const handleStatusChange = (event) => {
        setFormData({
            ...formData,
            status: event
        })
    }

    const handleUploadChange = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrl(url);
        });
        setFile(info?.file?.originFileObj || null)
    };

    const uploadButton = (
        <div>
            {loading && <LoadingOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const status = [
        { label: "Pending", value: "pending" },
        { label: "Paid", value: "paid" },
        { label: "Rejected", value: "rejected" },
    ]

    // useEffect(() => {
    //   setFormData({
    //     status: "",
    //   })
    //   setImageUrl()

    // }, [])

    const handleSubmit = async () => {
        let fData = new FormData()
        fData.append("bank_name", data.bank_name)
        fData.append("branch_name", data.branch_name)
        fData.append("currency", data.currency)
        fData.append("country", data.country)
        fData.append("branch_code", data.branch_code)
        fData.append("iban", data.iban)
        fData.append("account_number", data.account_number)
        fData.append("amount", data.amount)
        if (file) {
            fData.append("file", file)
        }
        if (formData.status != '') {
            fData.append("status", formData.status)
        }
        else {
            fData.append("status", "pending")
        }
        const res = await updateWithdrawalAPI(data?._id, fData)
        if (res.data != null) {
            toast.success("Recipt Uploaded Successfully")
            setCurrentPage("all")
        }
        else toast.error(res.error)

    }


    return (
        <>
            <div className="main">
                <div className="section">
                    <div className="heading">
                        <BsArrowLeftShort className="icon" onClick={closePage} />
                        <h2 className='heading'>Withdrawal Detail</h2>
                    </div>
                    <div className="detail-section">
                        <div class="Inputfield snipcss-ch2Ss">
                            <div class="field1 field">
                                <div class="lableName">Country</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.country} disabled /></span>
                            </div>
                            <div class="field2 field">
                                <div class="lableName">Currency</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.currency} disabled /></span>
                            </div>
                        </div>
                        <div class="Inputfield snipcss-ch2Ss">
                            <div class="field1 field">
                                <div class="lableName">Bank Name</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.bank_name} disabled /></span>
                            </div>
                            <div class="field2 field">
                                <div class="lableName">Branch Code</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.branch_code} disabled /></span>
                            </div>
                        </div>
                        <div class="Inputfield snipcss-ch2Ss">
                            <div class="field1 field">
                                <div class="lableName">Branch Name</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.branch_name} disabled /></span>
                            </div>
                            <div class="field2 field">
                                <div class="lableName">Account Number</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.account_number} disabled /></span>
                            </div>
                        </div>
                        <div class="Inputfield snipcss-ch2Ss">
                            <div class="field1 field">
                                <div class="lableName">IBAN Number </div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.iban} disabled /></span>
                            </div>
                            <div class="field2 field">
                                <div class="lableName">Amount</div>
                                <span class="ant-input-affix-wrapper ant-input-affix-wrapper-lg css-dev-only-do-not-override-3rel02 ant-input-outlined eduInput">
                                    <input type="text" placeholder="Specialty" name="specialty" class="ant-input ant-input-lg css-dev-only-do-not-override-3rel02" value={data?.amount} disabled /></span>
                            </div>
                        </div>
                        {UserData?.isSuperAdmin ?
                            <>
                                <div className="heading ">
                                    <h2 className='heading'>Update Withdrawal</h2>
                                </div>
                                <div className="detail-row">
                                    <div className="detail-col">
                                        <div className="title">
                                            <p>Upload Receipt :</p>
                                        </div>
                                        <div className="detail">
                                            <>
                                                <Upload
                                                    name="image"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    beforeUpload={beforeUpload}
                                                    onChange={handleUploadChange}
                                                >
                                                    {imageUrl ? (
                                                        <div className="imgBox">
                                                            <img
                                                                src={imageUrl}
                                                                alt="avatar"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        uploadButton
                                                    )}
                                                </Upload>
                                            </>
                                        </div>
                                    </div>
                                    <div className="detail-col">
                                        <div className="field2 field" id='residence'>
                                            <div className="lableName">Amount Status</div>
                                            <div className="inputselect">
                                                {/* <div className="selecticon"><Global className='iconInfo' /></div> */}
                                                <Select
                                                    name="status"

                                                    placeholder='Status'
                                                    value={[formData?.status]}
                                                    bordered={false}
                                                    className='selector'
                                                    getPopupContainer={() => document.getElementById('residence')}

                                                    onChange={handleStatusChange}
                                                    options={status}
                                                    removeIcon={<></>}
                                                />

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="detail-row">
                                    <div className="detail-col">
                                        <div className="submit-button">
                                            <Button className='yellowGraBtn createRoleBtn' style={{ width: "120px" }} loading={loading} onClick={handleSubmit}> Save </Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {data.image != null &&
                                    <>
                                        <div className="heading ">
                                            <h2>View Recipt</h2>
                                        </div>
                                        <div className="detail-row">
                                            <div className="detail-col">
                                                <div className="submit-button">
                                                    <>
                                                        <Button className='yellowGraBtn createRoleBtn' style={{ width: "150px" }} loading={loading} onClick={() => setVisible(true)} > View Recipt </Button>
                                                        <Image
                                                            width={200}
                                                            style={{ display: 'none' }}
                                                            preview={{
                                                                visible,
                                                                scaleStep,
                                                                src: data?.image?.Location,
                                                                onVisibleChange: value => {
                                                                    setVisible(value);
                                                                },
                                                            }}
                                                        />
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div >
            </div >
        </>
    )
}
export default DetailWithdrawal;