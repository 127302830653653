import React, { useEffect, useState } from "react";
import { DatePicker, Skeleton, Progress, Collapse } from "antd";
import Cards from "./Components/ReportCards/teacherCard";
import BarChart from "./Components/meetingChart";
import { GetMeetingDashboardData } from "./../../../../API/meeting";
import { toast } from "react-toastify";
import "./Home.scss";
import "./newstyles.scss";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const Home = () => {
    const [toggle, setToggle] = useState(null);
    const [staticData, setStaticData] = useState(null);

    const gettingDashboardData = async () => {
        const madrasaUserData = JSON.parse(localStorage.getItem("madrasaUserData"));
        const role = madrasaUserData._id;
        let res = await GetMeetingDashboardData(role);
        if (res.error != null) {
            toast.error(res.error);
        } else {
            setStaticData(res.data);
        }
    };

    useEffect(() => {
        gettingDashboardData();
    }, []);

    const renderAssignmentSummary = () => {
        if (!staticData) {
            return (
                <Skeleton active />
            );
        }

        return (
            <div className="assignmentSummary">
                <Collapse accordion>
                    <Panel header="Assignment Summary" key="1">
                        <div className="progressItem">
                            <div className="progressNumbers">{staticData.totalMeetings}</div>
                            <div className="progressText">Total Meetings</div>
                        </div>
                        <Progress percent={staticData.totalMeetings} status="success" trailColor="#fcd117" showInfo={false} />

                        <div className="progressItem">
                            <div className="progressNumbers">{staticData.pendingMeetings}</div>
                            <div className="progressText">Pending Meetings</div>
                        </div>
                        <Progress percent={staticData.pendingMeetings} status="success" trailColor="#fcd117" showInfo={false} />

                        <div className="progressItem">
                            <div className="progressNumbers">{staticData.completedMeetings}</div>
                            <div className="progressText">Completed Meetings</div>
                        </div>
                        <Progress percent={staticData.completedMeetings} status="success" trailColor="#fcd117" showInfo={false} />

                        <div className="progressItem">
                            <div className="progressNumbers">{staticData.failedMeetings}</div>
                            <div className="progressText">Failed Meeting</div>
                        </div>
                        <Progress percent={staticData.failedMeetings} status="success" trailColor="#fcd117" showInfo={false} />
                    </Panel>
                </Collapse>
            </div>
        );
    };

    return (
        <div className="dashboardHomeContainers">
            <div className="box">
                <Cards staticsData={staticData} />

                <div className="reportBox">
                    {renderAssignmentSummary()}

                    <div className="weeklyWiseMeetings">
                        <div className="subHeading">Weekly Wise Meetings</div>
                        <div className="chart" >
                            <BarChart /> {/* Use the BarChart component */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
