import React, { useState } from "react";

// ANT-D | MUI :
import { Button, Input } from "antd";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

// ICONS | Assets :
import { BsArrowLeftShort } from "react-icons/bs";

// APIs :
import { CreatEventAPI } from "API/event";
// Helpers :
import { toast } from "react-toastify";
import dayjs from "dayjs";

// CSS :
import "./AddMarqueeEvent.scss";





const AddMaqueeEvent = ({ closePage, setCurrentPage }) => {

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: dayjs()
  })
  const [loading, setLoading] = useState(false)


  const enteringFormData = (event) => {
    let { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const enteringDate = (event) => {
    setFormData({
      ...formData,
      date: event
    })
  };


  const handleCreateEvent = async () => {
    setLoading(true)
    let res = await CreatEventAPI(formData)
    if (res.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res.data?.message)
      closePage()
    }
    setLoading(false)
  }

  return (
    <>
      <div className="AddEventMainMarquee">
        <div className="flexLineSpace">
          <div className="heading upper"><BsArrowLeftShort className='icon cursor' onClick={closePage} /><div className="head">Add Marquee</div></div>
        </div>
        <div className="AddBodyMain">
          <div className="Events">
            <div className="inputFields">
              <div className="lableName">Marquee Title</div>
              <Input
                size="large"
                className="eventInput"
                type="text"
                placeholder="Event Title"
                value={formData?.title}
                name="title"
                onChange={enteringFormData}
              />

              <div className="lableName">Marquee Description</div>
              <Input.TextArea
                rows={6}
                size="large"
                className="eventDescription"
                type="text"
                placeholder="Event Deescription"
                value={formData?.description}
                name="description"
                onChange={enteringFormData}
              />
              <div className="BtnReadMore">
                <Button className="yellowGraBtn" loading={loading} onClick={handleCreateEvent}>Create Marquee</Button>
              </div>
            </div>
          </div>
          <div className="CalenderMainMarquee">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <DemoItem label="">
                  <DateCalendar value={formData.date} onChange={enteringDate} />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMaqueeEvent;
