import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCheck } from 'react-icons/bs';
import { Button, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import MadrasaImage from '../../../../Assets/Images/loginLogo.png';
import { IoClose } from "react-icons/io5";
import Fade from 'react-reveal/Fade';
import 'react-phone-input-2/lib/bootstrap.css';
import { CreatePasswordAPI } from '../../../../API/auth';
import { toast } from 'react-toastify';
import MessagePop from './../../../../Components/MessagePopup/message';

const RegisterPassword = ({ formData, setFormData, currentStep, handleChangeStep }) => {
  const navigate = useNavigate();

  const [lengthError, setLengthError] = useState(true);
  const [upperCaseError, setUpperCaseError] = useState(true);
  const [lowerCaseError, setLowerCaseError] = useState(true);
  const [numberSpecialError, setNumberSpecialError] = useState(true);
  const [passwordMatchError, setPasswordMatchError] = useState(null);
  const [stepStatus, setStepStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const enteringFormData = (event) => {
    let { name, value } = event.target;
    switch (name) {
      case "confirmPassword":
        if (formData.password !== value) {
          setPasswordMatchError("Password doesn't match");
        } else {
          setPasswordMatchError(null);
        }
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePasswordSet = async () => {
    setLoading(true);
    let res = await CreatePasswordAPI({
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      role: formData?.role,
      gender: formData?.gender,
      password: formData?.password,
      otpCode: formData?.otpCode
    });
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setIsModalOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000); // Adjust the delay as needed
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!formData?.password) {
      setLengthError(true);
      setUpperCaseError(true);
      setLowerCaseError(true);
      setNumberSpecialError(true);
    } else {
      setLengthError(formData?.password.length < 8);
      setUpperCaseError(!/[A-Z]/.test(formData?.password));
      setLowerCaseError(!/[a-z]/.test(formData?.password));
      setNumberSpecialError(!(/\d/.test(formData?.password) && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(formData?.password)));
    }
  }, [formData?.password]);

  useEffect(() => {
    if (!formData?.password) {
      setStepStatus(false);
    } else {
      setStepStatus(!(formData?.password !== formData?.confirmPassword || lengthError || upperCaseError || lowerCaseError || numberSpecialError));
    }
  }, [formData, lengthError, upperCaseError, lowerCaseError, numberSpecialError]);

  return (
    <div className='registerBox'>
      <div className="leftSection">
        <form action="users" method='post'>
          <div className="heading">Create Your Password</div>
          <div className="verification">
            <p>Please create Password for Your Account</p>
          </div>
          <div className="flexFields">
            <div className="field inputBox">
              <div className="name">Password</div>
              <Input.Password className='registerInput' placeholder="Password" name='password' onChange={enteringFormData} value={formData.password} />
            </div>
            <div className="field inputBox">
              <div className="name">Confirm Password</div>
              <Input.Password className='registerInput' placeholder="Password" name='confirmPassword' onChange={enteringFormData} value={formData.confirmPassword} />
              {passwordMatchError && <div className="errorMessage">{passwordMatchError}</div>}
            </div>
            <div className="passwordType">
              <div className="options">
                {!lengthError ? (
                  <div className="radio">
                    <BsCheck className="icon" />
                  </div>
                ) : (
                  <div className="dangerRadio">
                    <IoClose className="icon" />
                  </div>
                )}
                <p>At least 8 characters</p>
              </div>
              <div className="options">
                {!lowerCaseError ? (
                  <div className="radio">
                    <BsCheck className="icon" />
                  </div>
                ) : (
                  <div className="dangerRadio">
                    <IoClose className="icon" />
                  </div>
                )}
                <p>One lowercase character</p>
              </div>
              <div className="options">
                {!upperCaseError ? (
                  <div className="radio">
                    <BsCheck className="icon" />
                  </div>
                ) : (
                  <div className="dangerRadio">
                    <IoClose className="icon" />
                  </div>
                )}
                <p>One uppercase character</p>
              </div>
              <div className="options">
                {!numberSpecialError ? (
                  <div className="radio">
                    <BsCheck className="icon" />
                  </div>
                ) : (
                  <div className="dangerRadio">
                    <IoClose className="icon" />
                  </div>
                )}
                <p>One number & symbol</p>
              </div>
            </div>
            <div className="registerButton">
              <Button disabled={!stepStatus} className='yellowBtn' loading={loading} onClick={handlePasswordSet}>
                Next <RightOutlined />
              </Button>
              {isModalOpen && <MessagePop isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message="Account Created Successfully" />}
            </div>
          </div>
        </form>
      </div>
      <div className="rightSection">
        <div className="loginBio">
          <div className="madrasaLogo">
            <Fade left>
              <img src={MadrasaImage} alt="" />
            </Fade>
          </div>
          <Fade left>
            <div className="content">
              <div className="heading">A few more clicks to sign in to your account.</div>
              <p className="para">Manage all your e-commerce accounts in one place</p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default RegisterPassword;
