import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { Tooltip, Tag, Col, Row } from "antd";

// Components :
import Table from "../../../Users/Component/table/Table";
import ConfirmationModel from "../../../../../../Components/ConfirmationModel/ConfirmationModel";
import TransactionDetail from "../TransactionDetail/TransactionDetail";
import PreLoader from "Components/PreLoader/PreLoader";

// Assets | ICONS :
import EditIcon from "../../../../../../Assets/Images/editIcon.png";
import BagIcon from "../../../../../../Assets/Images/bagIcon.png";
import { GrView } from "react-icons/gr";

// API :
import { DeleteTransactionAPI, GetAllTransactionsAPI } from "../../../../../../API/transaction";
// Redux :
import { useSelector } from "react-redux";
// Helpers :
import { toast } from "react-toastify";
import ROLES from "Utils/Roles";

// CSS :
import "./AllTransaction.scss";





const view = <span>View</span>;
const Transactions = ({
  data,
  setData,
  loading,
  setLoading,
  reload,
  setReload,
  filteredData,
  setFilteredData,
}) => {

  const UserData = useSelector(state => state.userData)

  const [searchInput, setSearchinput] = useState("");
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showProfilePage, setShowProfilePage] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    transcationID: null,
    loading: false,
  });

  const closeProfilePage = () => {
    setShowProfilePage(false);
    setSelectedTransaction(null);
    setReload(!reload);
  };

  const renderStatusTag = (_, data) => {
    let tagColor, tagText;

    switch (data.status) {
      case "received":
        tagColor = "yellow";
        tagText = "Received";
        break;
      case "paid":
        tagColor = "green";
        tagText = "Paid";
        break;
      case "refund":
        tagColor = "default";
        tagText = "Refund";
        break;
      case "pending":
      default:
        tagColor = "red";
        tagText = "Pending";
    }

    return (
      <Tag
        color={tagColor}
        style={{
          borderRadius: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        {tagText}
      </Tag>
    );
  };

  const openProfilePage = (data) => {
    setSelectedTransaction(data);
    setShowProfilePage(true);
  };

  const getAllTransactions = async () => {
    setLoading(true);
    const res = await GetAllTransactionsAPI();
    if (res.error != null) {
      toast.error(res.error);
    } else {
      let transactionData = res?.data?.result;

      if (transactionData && transactionData.length >= 1) {
        let result;
        if (UserData.role?.name == ROLES.Teacher) {
          result = transactionData.map(transaction => {
            let sellers = transaction.sellers?.find(val => val?.userData?._id == UserData?._id);
            return {
              ...transaction,
              sellers,
              sources: sellers?.sources,
              balance: sellers?.balance,
              orderPrice: sellers?.orderPrice,
              sellerCharges: sellers?.charges
            }
          });
        } else {
          result = transactionData
        }
        setData(result || []);
      } else {
        setData([]);
      }

    }
    setLoading(false);
  };

  useEffect(() => {
    getAllTransactions();
  }, [reload]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "left",
      render: (_, data) => data?.createdAt?.slice(0, 10) || null,
      ellipsis: true,
    },
    ...(UserData && UserData?.isSuperAdmin || UserData?.role?.name != "teacher" ? [{
      title: "Saller Name",
      dataIndex: "sallerName",
      key: "sallerName",
      align: "left",
      render: (_, data) => {
        return data?.sellers?.map((seller, index) => `${seller?.userData?.firstName} ${seller?.userData?.lastName}`).join(" | ")
      },
      // `${data?.buyerId?.firstName ? data?.buyerId?.firstName : data?.shippingDetails?.firstName} ${data?.buyerId?.lastName ? data?.buyerId?.lastName : data?.shippingDetails?.lastName}`,
      ellipsis: true,
    }] : []),
    ...(UserData && UserData?.isSuperAdmin || UserData?.role?.name != "student" ? [{
      title: "Buyer Name",
      dataIndex: "buryerName",
      key: "buryerName",
      align: "left",
      render: (_, data) =>
        `${data?.buyer?.firstName ? data?.buyer?.firstName : data?.shippingDetails?.firstName} ${data?.buyer?.lastName ? data?.buyer?.lastName : data?.shippingDetails?.lastName}`,
      ellipsis: true,
    }] : []),
    // {
    //   title: "Title",
    //   dataIndex: "title",
    //   key: "title",
    //   ellipsis: true,
    //   align: "left",
    //   sorter: (a, b) => a?.title?.localeCompare(b?.title),

    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // ellipsis: true,
      align: "center",
      render: (_, data) => `${data?.orderType?.toLocaleUpperCase()}`,
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      className: "centerAntTableHeader"
    },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    //   ellipsis: true,
    //   align: "center",
    //   render: (_, data) => <>${`${data?.orderPrice}`}</>,
    // },
    // {
    //   title: "Service Charges",
    //   dataIndex: "charges",
    //   key: "charges",
    //   align: "center",
    //   render: (_, data) => <>${`${parseFloat(data?.charges).toFixed(1)}`}</>,
    //   ellipsis: true,
    // },
    {
      title: "Total Balance",
      dataIndex: "balance",
      key: "balance",
      render: (_, data) => <>${`${parseFloat(data?.balance).toFixed(1)}`}</>,
      align: "center",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      // className: "centered-column",
      // width:"100%",
      // render: (status, data) => renderStatusTag(status, data),
      render: (_, data) => <Tag className='userTags' color={data?.status == 'paid' ? 'green' : data?.status == "received" ? "yellow" : "red"}> {data?.status?.toLocaleUpperCase()} </Tag>,
      // ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: "center",
      ellipsis: true,
      render: (_, data) =>
        data?._id != "1" && (
          <>
            <div className="actionBox">
              <Tooltip placement="top" title={view}>
                <div
                  className="actionBtn"
                  onClick={() => openProfilePage(data)}
                >
                  <GrView className="icon cursor" />
                  {/* <img src={EditIcon} alt="" className="icon cursor" /> */}
                </div>
              </Tooltip>
            </div>
          </>
        ),
    },
  ];

  return (
    <>
      {showProfilePage ? (
        <TransactionDetail
          openPage={showProfilePage}
          closePage={closeProfilePage}
          selectedTransaction={selectedTransaction}
          UserData={UserData}
        />
      ) : (
        <div className="dashboardAllUsersContainer">
          <div className="flexLineSpace">
            <div className="heading upper customheading">Transactions</div>
          </div>
          {
            loading ?
              <PreLoader />
              :
              <div className="table">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table loading={loading} rows={data.length >= 1 ? data.reverse() : []} columns={columns} rowClassName={(record, index) => record?.status && record?.status == "paid" ? "" : "noRole"} />
                  </Col>
                </Row>
              </div>
          }
        </div>
      )}
    </>
  );
};

export default Transactions;