import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Select } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import EmailImage from '../../../../Assets/Images/registerEmail.png';
import { AiOutlineUserAdd } from "react-icons/ai";
import Fade from 'react-reveal/Fade';
import 'react-phone-input-2/lib/bootstrap.css';
import { GenrateEmailOtpAPI } from '../../../../API/auth';
import { GetAllRolesAPI } from 'API/role';
import { toast } from 'react-toastify';
import MessagePop from "./../../../../Components/MessagePopup/message";

const RegisterRole = ({ formData, setFormData, currentStep, handleChangeStep }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allRoles, setAllRoles] = useState([]);

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleNextStep = () => {
        handleChangeStep(currentStep + 1);
    };

    const handleVerifyEmail = async () => {
        setLoading(true);
        let res = await GenrateEmailOtpAPI({
            email: formData.email,
            phone: `+${formData.phone}`,
            role: formData.role,
        });

        if (res.error != null) {
            toast.error(res.error);
        } else {
            setIsModalOpen(true);
            // Delay navigation to the next step
            setTimeout(() => {
                handleNextStep();
            }, 2000); // Adjust the delay as needed
        }
        setLoading(false);
    };

    const gettingAllRoles = async () => {
        let res = await GetAllRolesAPI();
        if (res.error != null) {
            toast.error(res.error);
        } else {
            let rolesData = res?.data?.result || [];
            let process = rolesData
                .filter(role => role.name.toLowerCase() === "teacher" || role.name.toLowerCase() === "student")
                .map(role => ({
                    label: `${role.name.charAt(0).toUpperCase()}${role.name.slice(1)}`,
                    value: role._id
                }));
            setAllRoles(process);
        }
    };

    useEffect(() => {
        gettingAllRoles();
    }, []);

    return (
        <div className='registerBox'>
            <div className="leftSection">
                <form action="users" method='post'>
                    <div className="heading">What is your role?</div>
                    <div className="flexFields">
                        <div className="fields">
                            <div className="field inputBox">
                                <div className="name">Role</div>
                                <Select
                                    className='registerSelector'
                                    onChange={(value) => handleSelectChange("role", value)}
                                    value={formData.role}
                                    size='large'
                                    placeholder={<> <AiOutlineUserAdd /> Your Role </>}
                                    options={allRoles}
                                />
                            </div>
                        </div>
                        <div className="fields">
                            <div className="field inputBox">
                                <div className="name">Gender</div>
                                <Select
                                    className='registerSelector'
                                    onChange={(value) => handleSelectChange("gender", value)}
                                    value={formData.gender}
                                    size='large'
                                    placeholder={<> <AiOutlineUserAdd /> Your Gender </>}
                                    options={[
                                        {
                                            label: 'Male',
                                            value: 'male'
                                        },
                                        {
                                            label: 'Female',
                                            value: 'female'
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="registerButton">
                            <Button
                                className='yellowBtn'
                                loading={loading}
                                onClick={handleVerifyEmail}
                                disabled={!formData?.role || !formData?.gender ? true : false}
                            >
                                Next <RightOutlined />
                            </Button>
                            {isModalOpen && <MessagePop isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message="OTP sent successfully" />}
                        </div>
                    </div>
                </form>
            </div>
            <div className="rightSection">
                <div className="loginBio">
                    <div className="madrasaLogo">
                        <Fade left>
                            <img src={EmailImage} alt="Email" />
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterRole;
