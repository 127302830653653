import React from 'react'
import "./message.scss"
import { MdDone } from 'react-icons/md'
import { Modal } from 'antd'
import PrimaryButton from './../../Components/PrimaryButton/PrimaryButton'
import { IoWarningOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx';

const MessagePrompt = ({ isModalOpen, setIsModalOpen, message, heading = "Button", url, primaryFun, type }) => {
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            {isModalOpen &&
                <Modal className="freeBook" title={null} open={isModalOpen} onCancel={handleCancel} footer={null}>
                    <div className='messagePopUp'>
                        {type == "warning" ?
                            <div className='successLogo warning'>
                                <IoWarningOutline className='icon' />
                            </div>
                            : type == "danger" ?
                                <div className='successLogo danger'>
                                    <RxCross2 className='icon' />
                                </div>
                                :
                                <div className='successLogo success'>
                                    <MdDone className='icon' />
                                </div>}
                        <h2 className='heading'>{message}</h2>
                        <p className='para'>{url}</p>
                        {type == "danger" ?
                            ""
                            : (primaryFun ?
                                <div>
                                    <PrimaryButton heading={heading} primaryFun={primaryFun} />
                                </div>
                                : ""
                            )
                        }
                    </div>
                </Modal>
            }
        </>
    )
}

export default MessagePrompt



