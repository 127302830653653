import './AllWithdrawal.scss'
//  MUI | ANT-D :
import { Tooltip, Tag, Col, Row, Button, Modal, Select, Input, Skeleton } from "antd";

// Components :
import Table from "../../Users/Component/table/Table";
// Redux :

// styling
import PreLoader from "Components/PreLoader/PreLoader";
import { useSelector } from 'react-redux';
import { GrView } from "react-icons/gr";


const AllWithdrawal = ({ loading, data, setData, RoutePermissions, setReload, setCurrentPage, setSelectedWithdrawal }) => {
  let UserData = useSelector(state => state.userData)
  const view = <span>View</span>;

  const togglePage = () => {
    setCurrentPage("add")
  }
  const openDetailWithdrawal = (data) => {
    if (data) {
      setSelectedWithdrawal(data)
    } else {
      setSelectedWithdrawal(null)
    }
    setCurrentPage('detail')
  }



  const renderStatusTag = (_, data) => {
    let tagColor, tagText;

    switch (data.status) {
      case "pending":
        tagColor = "yellow";
        tagText = "Pending";
        break;
      case "paid":
        tagColor = "green";
        tagText = "Paid";
        break;
      case "refund":
        tagColor = "default";
        tagText = "Refund";
        break;
      case "rejected":
      default:
        tagColor = "red";
        tagText = "Rejected";
    }

    return (
      <Tag
        color={tagColor}
        style={{
          borderRadius: "10px",
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        {tagText}
      </Tag>
    );
  };

  const columns = [

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => `${data?.UserData?.firstName || data?.UserData?.lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => `${data?.UserData?.email}`,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "left",
      className: "start-column",
      render: (_, data) => `$${data?.amount || 0}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      // className: "centered-column",
      // width:"100%",
      // render: (status, data) => renderStatusTag(status, data),
      render: (_, data) => <Tag className='userTags' color={data?.status == 'paid' ? 'green' : data?.status == "pending" ? "yellow" : "red"}> {data?.status?.toLocaleUpperCase()} </Tag>,
      // ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: "center",
      ellipsis: true,
      render: (_, data) => data?.createdAt?.slice(0, 10) || null,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: "center",
      ellipsis: true,
      render: (_, data) =>
        data?._id != "1" && (
          <>
            <div className="actionBox">
              <Tooltip placement="top" title={view}>
                <div
                  className="actionBtn"
                  onClick={() => openDetailWithdrawal(data)}
                >
                  <GrView className="icon cursor" />
                  {/* <img src={EditIcon} alt="" className="icon cursor" /> */}
                </div>
              </Tooltip>
            </div>
          </>
        ),
    },

  ];

  return (
    <>
      <div className="dashboardAllUsersContainer">
        <div className="flexLineSpace">
          <div className="heading upper customheading">All Withdrawl Requests</div>
          <div className="buttonandFilter">
            {
              (!UserData?.isSuperAdmin || RoutePermissions?.includes("create")) &&
              <Button className='dashboardBtn' style={{
                width: "220px",
                background: "#0a1c29",
              }} onClick={() => togglePage(null)}> Add Withdrawal Request </Button>
            }
          </div>
        </div>
        {loading ? (
          <PreLoader />
        ) : (
          <div className="table">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  loading={loading}
                  rows={data}
                  columns={columns}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  )
}
export default AllWithdrawal