import React from "react";
import Admin from "./Home";
import Teacher from "./teacherdashboard";
import Student from "./studentDashboard"

function MyComponent() {
    const madrasaUserData = JSON.parse(localStorage.getItem("madrasaUserData"));
    const role = madrasaUserData.role.name;
    return (
        <>
            {role === "admin" && <Admin></Admin>}
            {role === "student" && <Student></Student>}
            {role === "teacher" && <Teacher></Teacher>}
        </>
    );
}

export default MyComponent;