import { useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"
import { useLocation } from "react-router-dom";


const Scroll = () => {

    let Location = useLocation();

    var options = {
        damping: 0.05,
        alwaysShowTracks: true,
        renderByPixels:true,
        plugins: {
            overscroll: true,
            damping: 0.01,
            effect: 'bounce',
            maxOverscroll: 10

        }
    }
    var options2 = {
        damping: 0.05,
        alwaysShowTracks: false,
        thumbMinSize:1,
        plugins: {
            overscroll: true,
            damping: 0.01,
            effect: 'bounce',
            maxOverscroll: 10

        }
    }

    useEffect(() => {
        // Scrollbar.use(OverscrollPlugin);
        // Scrollbar.init(document.body, options);
        Scrollbar.init(document.querySelector(".dashboardScroll"), options);
        Scrollbar.init(document.querySelector(".ant-layout-sider-children"), options2);
        let isTableExist = document.querySelector(".ant-table-content")
        // if(isTableExist){
            // Scrollbar.init(document.querySelector(".ant-table-content"), options);
        // }
        // Scrollbar.init(document.querySelector("#select"), options);
        return () => {
            // Scrollbar.destroy(document.body);
            Scrollbar.destroy(document.querySelector(".dashboardScroll"));
            Scrollbar.destroy(document.querySelector(".ant-table-content"));
            Scrollbar.destroy(document.querySelector(".ant-layout-sider-children"));
        }
    }, [Location.pathname])

    return null;
}

export default Scroll;