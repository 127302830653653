export const Languages = [
  {value : "Afrikaans"},
  {value : "Albanian"},
  {value : "Amharic"},
  {value : "Arabic"},
  {value : "Armenian"},
  {value : "Bengali"},
  {value : "Bosnian"},
  {value : "Bulgarian"},
  {value : "Catalan"},
  {value : "Chinese (Mandarin)"},
  {value : "Croatian"},
  {value : "Czech"},
  {value : "Danish"},
  {value : "Dutch"},
  {value : "English"},
  {value : "Esperanto"},
  {value : "Estonian"},
  {value : "Finnish"},
  {value : "French"},
  {value : "Galician"},
  {value : "German"},
  {value : "Greek"},
  {value : "Gujarati"},
  {value : "Hausa"},
  {value : "Hebrew"},
  {value : "Hindi"},
  {value : "Hungarian"},
  {value : "Icelandic"},
  {value : "Igbo"},
  {value : "Indonesian"},
  {value : "Irish"},
  {value : "Italian"},
  {value : "Japanese"},
  {value : "Kannada"},
  {value : "Khmer"},
  {value : "Korean"},
  {value : "Lao"},
  {value : "Latin"},
  {value : "Latvian"},
  {value : "Lithuanian"},
  {value : "Macedonian"},
  {value : "Malay"},
  {value : "Malayalam"},
  {value : "Marathi"},
  {value : "Nepali"},
  {value : "Norwegian"},
  {value : "Oriya"},
  {value : "Persian"},
  {value : "Polish"},
  {value : "Portuguese"},
  {value : "Punjabi"},
  {value : "Romanian"},
  {value : "Russian"},
  {value : "Serbian"},
  {value : "Sinhala"},
  {value : "Slovak"},
  {value : "Slovenian"},
  {value : "Somali"},
  {value : "Spanish"},
  {value : "Swahili"},
  {value : "Swedish"},
  {value : "Tamil"},
  {value : "Telugu"},
  {value : "Thai"},
  {value : "Turkish"},
  {value : "Ukrainian"},
  {value : "Urdu"},
  {value : "Vietnamese"},
  {value : "Welsh"},
  {value : "Xhosa"},
  {value : "Yiddish"},
  {value : "Yoruba"},
  {value : "Zulu"}
];