import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SiGoogleclassroom } from "react-icons/si";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { AiOutlineFileProtect } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { PiStudentDuotone } from 'react-icons/pi';
import { VideoTime } from 'iconsax-react';
import { MdOutlinePendingActions } from 'react-icons/md';
import { toast } from "react-toastify";
import dashboardCardPersonIcon from "../../../../../../Assets/Images/dashboardCardPersonIcon.png"
import "./Cards.scss";
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import StatsAPI from "./../../../../../../API/stats";
import ClassAPI from "./../../../../../../API/classstats";

const Cards = ({ staticsData }) => {
    const [CardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const gettingDashboardData = async () => {
        try {
            const res = await StatsAPI();
            const response = await ClassAPI();
            setCardsData([
                {
                    icon: FiUsers,
                    image: dashboardCardPersonIcon,
                    static: res.data.totalUsersLastMonthPercentage,
                    value: res.data.totalUsers,
                    name: 'Register Users',
                    route: 'users',
                    status: 'filter=approved',
                },
                {
                    icon: AiOutlineFileProtect,
                    image: dashboardCardPersonIcon,
                    static: res.data.pendingUsersPercentage,
                    value: res.data.pendingUsers,
                    name: 'Pending Users',
                    route: 'users',
                    status: 'filter=pending',
                },
                {
                    icon: PiStudentDuotone,
                    image: dashboardCardPersonIcon,
                    static: res.data.studentUsersPercentage,
                    value: res.data.studentUsers,
                    name: 'All Student',
                    route: 'users',
                    status: 'role=student',
                },
                {
                    icon: VideoTime,
                    image: dashboardCardPersonIcon,
                    static: response.data.percentageLastMonthMeetings,
                    value: response.data.totalMeetings,
                    name: 'Online Class',
                    route: 'meetings',
                    status: 'onlineClass',
                },
            ]);
        } catch (error) {
            toast.error(error.message || 'Failed to fetch dashboard data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        gettingDashboardData();
    }, []);

    const navigate = useNavigate();
    const handleChangeRoute = (val) => {
        navigate(`${val.route}?${val.status}`);
    };

    return (
        <div className="cardsContainer">
            {loading ? (
                <>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />

                </>

            ) : (
                CardsData.map((data, index) => (
                    <div className="hoverEffect" key={index} onClick={() => handleChangeRoute(data)}>
                        <div className="card">
                            <div className="iconBox">
                                <data.icon size={26} color="#575757" />
                                <div
                                    className={`${data.static < 0 ? 'counts countDanger' : 'counts'}`}
                                    style={data.static < 0 ? { background: 'var(--dangerGradient)' } : {}}
                                >
                                    {data.static}%
                                    {data.static < 0 ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
                                </div>
                            </div>
                            <div className="detials">
                                <div className="value">
                                    {data.value > 0 ? (
                                        data.value
                                    ) : data.value <= 0 ? (
                                        '0'
                                    ) : (
                                        <div className="skeleton">
                                            <Skeleton.Input shape="default" className="skel" block={true} active={true} size={26} style={{ width: '10px' }} />

                                        </div>

                                    )}
                                </div>
                                <div className="title">{data.name}</div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default Cards;
