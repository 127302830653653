import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { Tooltip, Tag, Col, Row, Button, Modal, Select, Input } from "antd";

// Components :
import Table from "../../../Users/Component/table/Table";

// Assets | ICONS :
import BagIcon from "../../../../../../Assets/Images/bagIcon.png";
import { RiInformationLine } from "react-icons/ri";

// API :
import {
  GetAllSubscriptionAPI,
  DeleteAPI,
} from "../../../../../../API/subscription";
// Helpers :
import { toast } from "react-toastify";

// CSS :
import "./AllDonationUser.scss";
import ConfirmationModel from "Components/ConfirmationModel/ConfirmationModel";
import PreLoader from "Components/PreLoader/PreLoader";
import { useSelector } from "react-redux";
import { Eye } from "iconsax-react";

const remove = <span>View</span>;

const { Option } = Select;

const AllDonationUser = ({
  data,
  setData,
  loading,
  setLoading,
  reload,
  setReload,
}) => {



  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      ellipsis: true,
      render: (_, data) => data?.createdAt?.slice(0, 10) || null,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => `${data?.buyer?.firstName || data?.shippingDetails?.firstName} ${data?.buyer?.lastName || data?.shippingDetails?.lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => `${data?.buyer?.email || data?.shippingDetails?.email}`,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "left",
      className: "start-column",
      render: (_, data) => `$${data?.balance || 0}`,
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "center",
    //   render: (_, data) =>
    //     data?._id != "1" && (
    //       <>
    //         <div className="actionBox">
    //           <Tooltip placement="top" title={remove}>
    //             <div className="actionBtn">
    //               <Eye
    //                 size="64"
    //                 color="#000"
    //               />
    //               <img
    //                 // src={BagIcon}
    //                 alt=""
    //                 className="icon cursor"
    //                 onClick={() => handleDeleteConfirmation(data)}
    //               />
    //             </div>
    //           </Tooltip>
    //         </div>
    //       </>
    //     ),
    // },
  ];

  return (
    <>
      <div className="dashboardAllUsersContainer">
        <div className="flexLineSpace">
          <div className="heading upper customheading">All Donations</div>
        </div>
        {loading ? (
          <PreLoader />
        ) : (
          <div className="table">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  loading={loading}
                  rows={data}
                  columns={columns}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default AllDonationUser;
