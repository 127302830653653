import React, { useState, useEffect } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { AiOutlineFileProtect } from "react-icons/ai";
import { FaGraduationCap } from "react-icons/fa";
import { PiStudentDuotone } from 'react-icons/pi';
import { VideoTime } from 'iconsax-react';
import { toast } from "react-toastify";
import { PiStudentFill } from "react-icons/pi";
import { RiPresentationFill } from "react-icons/ri";
import { MdAssignment } from "react-icons/md";
import { GrSchedule } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";


import dashboardCardPersonIcon from "../../../../../../Assets/Images/dashboardCardPersonIcon.png"
import dashboardCardPersonIcon2 from "../../../../../../Assets/icons/ph_student.png";
import dashboardCardPersonIcon3 from "../../../../../../Assets/icons/Group.png";
import dashboardCardPersonIcon4 from "../../../../../../Assets/icons/material-symbols_assignment-outline.png";
import dashboardCardPersonIcon5 from "../../../../../../Assets/icons/akar-icons_schedule.png";
import "./Cards.scss";
import { useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import StatsAPI from "./../../../../../../API/stats";
import ClassAPI from "./../../../../../../API/classstats";
import { GetMeetingDashboardData } from "./../../../../../../API/meeting";

const Cards = ({ staticsData }) => {
    const [CardsData, setCardsData] = useState([]);
    const [loading, setLoading] = useState(true);

    const gettingDashboardData = async () => {
        try {
            const madrasaUserData = JSON.parse(localStorage.getItem("madrasaUserData"));
            const role = madrasaUserData._id;
            const res = await GetMeetingDashboardData(role);

            setCardsData([
                {
                    icon: PiStudentFill,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'SEARCH & JOIN NEW',
                    route: 'users',
                    status: 'https://madrasa.io/teacher',
                },
                {
                    icon: RiPresentationFill,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'CURRENT MEETINGS',
                    route: 'users',
                    status: '/dashboard/meetings',
                },
                {
                    icon: MdAssignment,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'PENDING MEETINGS',
                    route: 'users',
                    status: '/dashboard/meetings',
                },
                {
                    icon: GrSchedule,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'FAILED MEETINGS',
                    route: 'meetings',
                    status: '/dashboard/meetings',
                },
                {
                    icon: GrSchedule,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'PURCHASED BOOKS',
                    route: 'meetings',
                    status: '/dashboard/purchasedbooks ',
                },
                {
                    icon: GrSchedule,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'TOTAL TRANSCATIONS',
                    route: 'meetings',
                    status: '/dashboard/transactions',
                }, {
                    icon: CgProfile,
                    image: dashboardCardPersonIcon,
                    static: 0,
                    value: 0,
                    name: 'MANAGE PROFILE',
                    route: 'meetings',
                    status: '/dashboard/profile',
                },

            ]);
        } catch (error) {
            toast.error(error.message || 'Failed to fetch dashboard data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        gettingDashboardData();
    }, []);

    const navigate = useNavigate();
    const handleChangeRoute = (val) => {
        const isFullUrl = val.status.startsWith('http://') || val.status.startsWith('https://');
        if (isFullUrl) {
            window.location.href = val.status; // Use full URL directly
        } else {
            navigate(`${val.status}`); // Use react-router's navigate for relative paths
        }
        // navigate(`${val.status}`);
    };

    const renderCard = (data, index) => (
        <div className="hoverEffect" key={index} onClick={() => handleChangeRoute(data)}>
            <div className="card">
                <div className="iconBox">
                    <data.icon size={26} color="#575757" />

                </div>
                <div className="detials">
                    <div className="title">{data.name}</div>
                </div>
            </div>
        </div>
    );


    return (
        <div className="cardsContainer">
            {loading ? (
                <>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </>
            ) : (
                <>
                    {CardsData.slice(0, 8).map((data, index) => renderCard(data, index))}
                </>
            )}
        </div>
    );
};

export default Cards;
