import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { Tooltip, Tag, Col, Row, Button, Modal, Rate, Input } from "antd";

// Components :
import Table from "../../../Users/Component/table/Table";
import ConfirmationModel from "Components/ConfirmationModel/ConfirmationModel";
import PreLoader from "Components/PreLoader/PreLoader";

// Assets | ICONS :
import BagIcon from "../../../../../../Assets/Images/bagIcon.png";
import { RiInformationLine } from "react-icons/ri";
import UserAvaterIcon from "Assets/Images/profileIcon.png"

// API :
import { GetAllPurchasedBooksAPI } from "API/booking";
// Helpers :
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ImgURLGEN from "Utils/ImgUrlGen";

// CSS :
import "./AllPurchasedBook.scss";
import Review from "../Review/Review";





const review = <span>Give Review</span>;

const AllPurchasedBook = ({
  data,
  setData,
  loading,
  setLoading,
  reload,
  setReload,
  RoutePermissions,
}) => {
  const UserData = useSelector((state) => state?.userData);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBookReview, setSelectedBookReview] = useState(null);


  const togglePopup = (data) => {
    setSelectedBookReview(!isPopupOpen ? data ? data : null : null)
    setIsPopupOpen(!isPopupOpen);
    // setReload(!reload)
  };


  const gettingAllPurchasedBooks = async () => {
    setLoading(true);
    const res = await GetAllPurchasedBooksAPI();
    if (res.error != null) {
      toast.error(res.error);
    } else {
      // console.log("--------------------->", res.data.result[0]?.bookings[0]?.sourcesData);
      setData(res.data.result[0]?.bookings[0]?.sourcesData || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    gettingAllPurchasedBooks();
  }, [reload]);

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (_, data) => <> <div className="avaterBox"> {data?.bookData?.image?.url ? <img src={ImgURLGEN(data?.bookData?.image)} alt="ERROR" /> : <UserAvaterIcon size={18} className='icon' />}  </div> </>,
      ellipsis: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => data?.bookData?.title,
    },
    {
      title: "Auther",
      dataIndex: "auther",
      key: "auther",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => `${data?.bookData?.auther?.firstName} ${data?.bookData?.auther?.lastName}`,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "left",
      className: "start-column",
      render: (_, data) => `${data?.bookData?.price == "0" ? "Free" : "Paid"}`,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "left",
      className: "start-column",
      ellipsis: true,
      render: (_, data) => `$${data?.bookData?.price}`,
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      align: "center",
      ellipsis: true,
      render: (_, data) =>
        <>
          <div className="actionBox">
            <Tooltip placement="top" title={review}>
              <div className="actionBtn" onClick={() => !data?.review?.value && togglePopup(data)}>
                <Rate style={{ fontSize: "15px" }} value={data?.review?.value || null} />
              </div>
            </Tooltip>
          </div>
        </>
    },
  ];

  return (
    <>
      <div className="dashboardAllUsersContainer">
        <div className="flexLineSpace">
          <div className="heading upper customheading">Purchased Books</div>
        </div>
        {
          isPopupOpen &&
          <Modal
            className="reviewModel"
            title={null}
            visible={isPopupOpen}
            onCancel={togglePopup}
            footer={null}
            width={"700px"}

          >
            <Review data={selectedBookReview} togglePopup={togglePopup} setReload={setReload} />
          </Modal>
        }

        {loading ? (
          <PreLoader />
        ) : (
          <div className="table">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  loading={loading}
                  rows={data.length >= 1 ? data.reverse() : []}
                  columns={columns}

                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default AllPurchasedBook;
