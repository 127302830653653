import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { GetMeetingChart } from "./../../../../../API/meeting";
import { toast } from "react-toastify";
import { Skeleton, Empty } from "antd";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
    const [chartData, setChartData] = useState({
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                label: "Meetings",
                data: [0, 0, 0, 0, 0, 0, 0],
                backgroundColor: "#FFB800",
                borderColor: "#FFB800",
                borderWidth: 1,
                borderRadius: 4,
            },
        ],
    });
    const [loading, setLoading] = useState(true);
    const [hasMeetings, setHasMeetings] = useState(false);

    const gettingDashboardData = async () => {
        try {
            const madrasaUserData = JSON.parse(localStorage.getItem("madrasaUserData"));
            const role = madrasaUserData._id;
            const res = await GetMeetingChart(role);

            // Process the data to match the chart format
            const nextWeekMeetings = res.data.nextWeekMeetings;
            const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            const data = daysOfWeek.map(day => nextWeekMeetings[day] || 0);

            setHasMeetings(data.some(meetings => meetings > 0));

            setChartData({
                labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                datasets: [
                    {
                        label: "Meetings",
                        data,
                        backgroundColor: "#FFB800",
                        borderColor: "#FFB800",
                        borderWidth: 1,
                        borderRadius: 4,
                    },
                ],
            });
        } catch (error) {
            toast.error(error.message || 'Failed to fetch dashboard data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        gettingDashboardData();
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                font: {
                    size: 16,
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 10, // Ensure the y-axis can handle up to 10 meetings per day
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <div className="chartdiv" style={{ width: '700px' }}>
            {loading ? (
                <Skeleton active />
            ) : hasMeetings ? (
                <Bar data={chartData} options={options} />
            ) : (
                <Empty description="There are no meetings this week" />
            )}
        </div>

    );
};

export default BarChart;
