import React, { useEffect, useState } from 'react'
// Component
import AddWithdrawalRequest from './AddWithdrawalRequest/AddWithdrawalRequest'
import AllWithdrawal from './AllWithdrawal/AllWithdrawal'
import DetailWithdrawal from './Detailwithdrawal/DetailWithdrawal'
// API
import { GetAllWithdrawalAPI, TotalWithdrawalcurrentUserAPI, } from 'API/withdrawal'

// Helpers :
import { toast } from 'react-toastify';

// Styling
import './Withdrawal.scss'

const Withdrawal = (props) => {
    let RoutePermissions = props?.permissions || []

    const [currentPage, setCurrentPage] = useState("all")
    const [data, setData] = useState([]);
    const [userwithdrawal, setUserWithdrawal] = useState("")
    const [userPendingWithdrawal, setUserPendingWithdrawal] = useState("")
    const [userBalance, setUserBalance] = useState("")
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null)
    // const [selectedEvent, setSelectedEvent] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)



    const closePage = () => {
        setCurrentPage("all")
        setReload(!reload)
    }


    const gettingAllWithdrawal = async () => {
        let res = await GetAllWithdrawalAPI("withdrawal")
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setData(res?.data?.result)
        }
    }
    useEffect(() => {
        gettingAllWithdrawal()
    }, [currentPage])

    const getuserwithdrawal = async () => {
        let res = await TotalWithdrawalcurrentUserAPI("withdrawal")
        if (res.error != null) {
            toast.error(res.error)
        }
        else {
            setUserWithdrawal(res.data?.sumOfAmounts)
            setUserPendingWithdrawal(res.data?.pendingsumOfAmounts)
            setUserBalance(res.data?.balance)
        }
    }
    useEffect(() => {
        getuserwithdrawal()
    }, [])

    // useEffect(() => {
    // }, [userwithdrawal]);

    return (
        <>
            <div className="dashboardEventsContainer">
                {
                    currentPage == "all" ?
                        <AllWithdrawal closePage={closePage} data={data} setData={setData} loading={loading} RoutePermissions={RoutePermissions} setReload={setReload} setCurrentPage={setCurrentPage} setSelectedWithdrawal={setSelectedWithdrawal} /> :
                        currentPage == "add" ?
                            <AddWithdrawalRequest setCurrentPage={setCurrentPage} userPendingWithdrawal={userPendingWithdrawal} closePage={closePage} userwithdrawal={userwithdrawal} userBalance={userBalance} />
                            :
                            <DetailWithdrawal selectedWithdrawal={selectedWithdrawal} closePage={closePage} data={data} setCurrentPage={setCurrentPage} />

                }
            </div>
        </>
    )
}
export default Withdrawal