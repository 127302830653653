import React from 'react';

// ANT-D | MUI :
import { List, Avatar, Typography, Divider, Card } from 'antd';

// Assets | ICONS :
import { UserOutlined } from '@ant-design/icons';
import { People } from 'iconsax-react';
import { FaMicrophone } from "react-icons/fa6";

// CSS :
import './Participants.scss'





const ParticipantsPanel = ({ meetingData }) => {
    return (
        <Card title={<> <People /> Participants </>} className="participants-container">
            <div className="participantsList">
                <div className="participant">
                    <div className="avater">
                        {
                            meetingData?.admin?.profileImage ?
                                <img src={meetingData?.admin?.profileImage?.url} alt="" />
                                :
                                <UserOutlined />
                        }
                    </div>
                    <div className="name">
                        <div className="title">{meetingData?.admin?.firstName}</div>
                        <div className="email">{meetingData?.admin?.email}</div>
                    </div>
                    <div className="icons">
                        <div className="icon">
                            <FaMicrophone />
                        </div>
                    </div>
                </div>
                {
                    meetingData?.participants &&
                    meetingData.participants.map((participant) => {
                        return (
                            <>
                                <div className="participant">
                                    <div className="avater">
                                        {
                                            participant?.profileImage ?
                                                <img src={participant?.profileImage?.url} alt="" />
                                                :
                                                <UserOutlined />
                                        }
                                    </div>
                                    <div className="name">
                                        <div className="title">{participant?.firstName}</div>
                                        <div className="email">{participant?.email}</div>
                                    </div>
                                    <div className="icons">
                                        <div className="icon">
                                            <FaMicrophone />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </Card>
    )};

export default ParticipantsPanel;