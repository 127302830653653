import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { Button, Tooltip } from "antd";

// Components :
// import Table from './Component/table/Table'
import ConfirmationModel from "../../../../Components/ConfirmationModel/ConfirmationModel";
import CreateUserForm from "../Users/Component/CreateUserForm/UserForm";


// API :
import { DeleteUserAPI, GetAllUsersAPI, GetAllTransactionsAPI } from "../../../../API/transaction";
// Helpers :
import { toast } from "react-toastify";

// CSS :
import "./Support.scss";
import AllSupportUser from "./Component/AllSupportUser/AllSupportUser";


const Support = (props) => {
  let RoutePermissions = props?.permissions || [];


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  return (
    <>
      <div className="dashboardUsersContainer">

        <AllSupportUser data={data} setData={setData} reload={reload} setReload={setReload} loading={loading} setLoading={setLoading} RoutePermissions={RoutePermissions} />


      </div>
    </>
  );
};

export default Support;
