import React, { useState } from 'react'

// MUI | ANT-D :
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { CircularProgress } from '@mui/material';
import { Button, Input } from 'antd';

// ICONS | Assets :
import { RiInformationLine } from "react-icons/ri";
import { BagCross } from "iconsax-react"

// Components :
import ConfirmationModel from 'Components/ConfirmationModel/ConfirmationModel';
// APIs :
import { DeleteEventAPI } from "API/event"
// Redux :
import { useSelector } from 'react-redux';
// Helpers :
import { toast } from 'react-toastify';

// CSS :
import "./AllMarqueeEvents.scss";





const AllMarqueeEvents = ({ allEvents, loading, togglePage, RoutePermissions, setReload }) => {

    let UserData = useSelector(state => state.userData)

    const [deleteConfirmation, setDeleteConfirmation] = useState({
        open: false,
        eventID: null,
        loading: false
    })

    const handleDeleteEventConfirmation = (event) => {
        setDeleteConfirmation({
            open: true,
            eventID: event?._id,
            loading: false
        })
    }
    const handleDeleteEvent = async () => {
        setDeleteConfirmation({
            ...deleteConfirmation,
            loading: true
        })
        let res = await DeleteEventAPI(deleteConfirmation.eventID)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
        }
        setDeleteConfirmation({
            open: false,
            eventID: null,
            loading: false
        })
        setReload((preVal) => !preVal)
    }
    const handleNotDeleteEvent = () => {
        setDeleteConfirmation({
            open: false,
            eventID: null,
            loading: false
        })
    }

    // const columns = [
    //     {
    //         title: 'Date',
    //         dataIndex: 'date',
    //         key: 'date',
    //         render: (_, data) => data?.createdAt?.slice(0, 10) || null
    //     },
    //     {
    //         title: 'Event Title',
    //         dataIndex: 'title',
    //         key: 'title',
    //         width: "300px",
    //         ellipsis: true,
    //         render: (_, data) => `${data?.title}`,

    //     },
    //     {
    //         title: 'Description',
    //         dataIndex: 'description',
    //         key: 'description',
    //         width: "300px",
    //         ellipsis: true,
    //     },
    //     {
    //         title: 'Actions',
    //         dataIndex: 'action',
    //         key: 'action',
    //         align: "center",
    //         render: (_, data) => <>
    //             <div className="actionBox">
    //                 {
    //                     (UserData?.isSuperAdmin || RoutePermissions?.includes("edit")) &&
    //                     <Tooltip placement="top" title={edit}>
    //                         <div className="actionBtn" onClick={() => togglePage(data)}>
    //                             <img src={EditIcon} alt="" className='icon cursor' />
    //                         </div>
    //                     </Tooltip>
    //                 }
    //                 {
    //                     (UserData?.isSuperAdmin || RoutePermissions?.includes("delete")) &&
    //                     <Tooltip placement="top" title={remove}>
    //                         <div className="actionBtn" onClick={() => handleDeleteEventConfirmation(data)}>
    //                             <img src={BagIcon} alt="" className='icon cursor' />
    //                         </div>
    //                     </Tooltip>
    //                 }
    //             </div>
    //         </>

    //     },

    // ]

    return (
        <>
            <div className="allEventsBoxMarquee">
                <div className="flexLineSpace">
                    <div className="heading upper customhead">
                        All Marquee Events
                    </div>
                    <div className="buttonandFilter">
                        {
                            (UserData?.isSuperAdmin || RoutePermissions?.includes("create")) &&
                            <Button className='dashboardBtn' style={{
                                width: "120px",
                                background: "#0a1c29",
                            }} onClick={() => togglePage(null)}> Add Marquee </Button>
                        }
                    </div>
                </div>

                <div className="ViewBodyMain">
                    <div className="Events">
                        {
                            loading ?
                                <CircularProgress />
                                : allEvents && allEvents.length >= 1 ?
                                    allEvents?.map((data) => {
                                        return (
                                            <>
                                                <div className="inputFields">
                                                    <div className="lableName">Marquee Title</div>
                                                    <Input
                                                        size="large"
                                                        className="eventInput"
                                                        type="text"
                                                        placeholder="Event Title"
                                                        disabled
                                                        value={data?.title}
                                                    />

                                                    <div className="lableName">Marquee Description</div>
                                                    <Input.TextArea
                                                        rows={2}
                                                        size="large"
                                                        className="eventDescription"
                                                        type="text"
                                                        placeholder="Event Deescription"
                                                        disabled
                                                        value={data?.description}
                                                    />
                                                    <div className="BtnReadMore">
                                                        <Button className="redGradientBtn" onClick={() => handleDeleteEventConfirmation(data)} loading={deleteConfirmation?.eventID == data?._id && deleteConfirmation?.loading}>  Delete Marquee</Button>
                                                        {/* <Button className="readMoreBtn">Read More</Button> */}

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    :
                                    <>
                                        No Event Found
                                    </>
                        }
                    </div>
                    <div className="CalenderMain">
                        {/* <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                                <DemoItem label="" >
                                    <DateCalendar disabled />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <ConfirmationModel open={deleteConfirmation.open} onOk={handleDeleteEvent} onCancel={handleNotDeleteEvent} confirmLoading={deleteConfirmation.loading}>
                <div className="deleteModel">
                    <div className="titleBox">
                        <RiInformationLine className='icon' /> <div className="title"> Are you sure you want to delete this Event? </div>
                    </div>
                </div>
            </ConfirmationModel>
        </>
    )
}

export default AllMarqueeEvents