import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { Tooltip, Tag, Col, Row, Button, Modal, Select, Input } from "antd";

// Components :
import Table from "../../../Users/Component/table/Table";

// Assets | ICONS :
import BagIcon from "../../../../../../Assets/Images/bagIcon.png";
import { RiInformationLine } from "react-icons/ri";

// API :
// Helpers :
import { toast } from "react-toastify";

// CSS :
import "./AllSupportUser.scss";
import ConfirmationModel from "Components/ConfirmationModel/ConfirmationModel";
import PreLoader from "Components/PreLoader/PreLoader";
import { useSelector } from "react-redux";
import { Eye } from "iconsax-react";
import { RiMailSendLine } from "react-icons/ri";
// import SendMail from "../../SendMail/SendMail";
// import { useNavigate } from "react-router-dom";
// import MailLogo from "../../../../../../Assets/Images/mailPicture.jpg"
import { TfiEmail } from "react-icons/tfi";
import { GetAllContactsAPI, ReplyContactAPI } from "../../../../../../API/support"


const { TextArea } = Input;

const AllSupportUser = ({
  data,
  setData,
  loading,
  setLoading,
  reload,
  setReload,
  RoutePermissions,

}) => {
  // const [mailText, setmailText] = useState("")
  const [Maildata, setMaildata] = useState(null)
  const [inputValue, setInputValue] = useState("");
  const [replyloading, setReplyloading] = useState(false)
  const [RoutePermission, SetRoutePermission] = useState(true);



  const AdminData = async () => {
    const userData = JSON.parse(localStorage.getItem("madrasaUserData"));
    const { role } = userData;
    const supportRoute = role.routes.find(route => route.key === "/support");
    const res = supportRoute && supportRoute.permissions.includes("edit");
    const superAdmin = UserData?.isSuperAdmin;
    // Set the route permission based on the conditions
    let routePermission = false;
    if (superAdmin === true || res === true) {
      routePermission = true;
    }
    SetRoutePermission(routePermission);


  };

  useEffect(() => {
    AdminData();
  }, []);


  // const mailSendData = () => {
  //   console.log(mailText)
  // }

  const UserData = useSelector(state => state?.userData)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (data) => {
    setMaildata(data)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const closePopup = () => {
    setMaildata(null);
    setInputValue("");
    setIsModalOpen(false);
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Send Mail Function
  const handleSendEmail = async (e) => {
    e.preventDefault();
    setReplyloading(true);
    const payload = {
      message: inputValue,
      contactId: Maildata?._id,
    };
    const res = await ReplyContactAPI(payload);
    if (res.error != null) {
      toast.error(res?.error.message);
    } else {
      toast?.success(res.data.message);
    }
    setReplyloading(false);
    closePopup();
  };


  const getAllContactData = async () => {
    setLoading(true);
    const res = await GetAllContactsAPI();
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setData(res?.data?.result || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllContactData();
  }, [reload]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, data) => data?.createdAt?.slice(0, 10) || null,
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      ellipsis: true,
      // className: "start-column",
      render: (_, data) => `${data?.firstName} ${data?.lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "left",
      ellipsis: true,

      // className: "start-column",
      render: (_, data) => data.email,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      ellipsis: true,

      // className: "start-column",
      render: (_, data) => data?.subject,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      align: "left",
      ellipsis: true,
      width: 250,
      // className: "start-column",
      render: (_, data) => data.message,
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, data) => (
        <>
          {/* || RoutePermissions?.includes("edit") */}
          {RoutePermission &&
            <div className="actionBox1" onClick={() => showModal(data)} >
              <Tooltip title="Mail" >
                <div className="actionBtn1" >
                  <RiMailSendLine size={22} />
                </div>
              </Tooltip>
            </div>
          }
        </>

      ),

    },
  ];




  return (
    <>
      <div className="dashboardAllUsersContainer">
        <div className="flexLineSpace">
          <div className="heading upper customheading">Support Queries</div>

          {/* Message PopUp  */}
          <>
            <Modal className="emailReplyModel" open={isModalOpen} onCancel={closePopup} footer={[
              <Button
                key="send"
                onClick={handleSendEmail}
                className="dashboardBtn"
                style={{
                  width: "120px",
                  background: "linear-gradient(274.64deg, #02a800 5.4%, #1ad56c 94.36%)",
                  color: "#fff"
                }}
                loading={replyloading}
              >
                Send
              </Button>,
              <Button
                key="cancel"
                onClick={closePopup}
                className="dashboardBtn"
                style={{ width: "120px" }}>
                Cancel
              </Button>,

            ]} >

              <div className="heading">
                <div className="mailLogo">
                  <TfiEmail />
                </div>
                <p>{Maildata?.firstName} <br /> <a href="">{Maildata?.email}</a> </p>
              </div>
              <p className="dataPara">{Maildata?.message}</p>
              {/* <TextArea rows={6} placeholder="Reply" className="textArea" value={mailText}   onChange={(e) => setmailText(e.target.value)} /> */}
              <TextArea rows={6} placeholder="Reply" value={inputValue} className="textArea" onChange={handleInputChange} />
            </Modal>
          </>
        </div>

        {loading ? (
          <PreLoader />
        ) : (
          <div className="table">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                  loading={loading}
                  rows={data}
                  columns={columns}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default AllSupportUser;