import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Modal, Table as AntdTable, Row, Col, Input } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { FaEdit, FaTrash } from "react-icons/fa";
import { BsCalendar } from "react-icons/bs";
import "./styles.css";

// CSS
import "./Table.scss";

export const responsiveArray = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

export const responsiveMap = {
    xs: '(max-width: 575px)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
    xxl: '(min-width: 1600px)',
};

const Table = ({
    columns = [],
    rows = [],
    hasEditDeleteAction = false,
    tableLeftActiveAction = "",
    tableLeftActions = [],
    hasSearch = false, // Default to false
    hasStartDate = false,
    hasEndDate = false,
    hasDropDownAction = false,
    hasRowSelection = false,
    descreaseRightActionWidth = false,
    dropDownActionList = [],
    dropDownListHanlder = (action) => console.log(action),
    tableLeftActionsHandler = (action) => console.log(action),
    actionHandler = (row) => console.log(row),
    searchHandler = (search) => console.log(search),
    rowClassName = null
}) => {
    const { confirm } = Modal;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState(""); // State for search text

    const HandleAction = (row) => {
        if (row && row.type === "delete") {
            confirm({
                title: 'Do you Want to delete?',
                icon: <ExclamationCircleFilled />,
                okType: "danger",
                onOk() {
                    actionHandler(row);
                },
                onCancel() {
                },
            });
            return;
        }
        actionHandler(row);
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    let tableColumns = [
        {
            title: 'Sr',
            key: "sr",
            width: "80px",
            render: (_, record, index) => (
                <div>
                    {index + 1}
                </div>
            )
        },
        ...columns
    ];

    if (hasEditDeleteAction) {
        let index = tableColumns.length;
        tableColumns[index] =
        {
            title: 'Action', key: 'action',
            render: (_, record) => (
                <div>
                    <button className="btn" onClick={() => HandleAction({ record, type: "edit" })}>
                        <FaEdit style={{ color: "skyblue" }} size={20} />
                    </button>
                    &nbsp;
                    <button className="btn redGradientBtn" onClick={() => HandleAction({ record, type: "delete" })}>
                        <FaTrash style={{ color: "red" }} size={20} />
                    </button>
                </div>
            ),
        }
    }

    // Function to filter rows based on search text
    const filteredRows = rows.filter(row =>
        Object.values(row).some(value =>
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleSearch = () => {
        setIsOpen(!isOpen);
    };



    return (
        <div className="custom-table">
            <Row align="middle" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={descreaseRightActionWidth ? 12 : 8}>
                    <Row align="middle">
                        {
                            tableLeftActions?.map((el, i) => (
                                <Col key={i} className="custome-table-left-action">
                                    <Link to="#" onClick={() => tableLeftActionsHandler(el)} {...(el === tableLeftActiveAction ? { className: "theme-btn btn-pad table-left-active-action" } : {})}>
                                        {el}
                                    </Link>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={descreaseRightActionWidth ? 12 : 16}>
                    <Row align="middle" justify="end">
                        {
                            hasDropDownAction &&
                            <Col xs={24} sm={10} md={10} lg={5} xl={5}>
                                <div className="verticaly-center">
                                    <select className="input" onChange={dropDownListHanlder}>
                                        {dropDownActionList?.map((el, i) => (
                                            <option value={el} key={i}> {el} </option>
                                        ))}
                                    </select>
                                    <BsCalendar size={30} />
                                </div>
                            </Col>
                        }
                        {
                            hasStartDate &&
                            <Col xs={24} sm={7} md={7} lg={5} xl={5}>
                                <div className="verticaly-center">
                                    <input type="text" value="2022/01/25" className="input" />
                                    <BsCalendar size={30} />
                                </div>
                            </Col>
                        }
                        {
                            hasEndDate &&
                            <Col xs={24} sm={7} md={7} lg={5} xl={5}>
                                <div className="verticaly-center">
                                    <input type="text" value="2022/01/25" className="input" />
                                    <BsCalendar size={30} />
                                </div>
                            </Col>
                        }
                        {
                            hasSearch &&
                            <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                                <div className="container">
                                    <div className={`search-icon ${isOpen ? 'open' : ''}`}>
                                        <input
                                            className="search-icon-input"
                                            placeholder="Search on Transforming"
                                            onChange={handleSearchChange}
                                        />
                                        <div className="search-icon-wrapper" onClick={toggleSearch}>
                                            <div className="search-icon-glass"></div>
                                            <div className="search-icon-handle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            <br />
            <AntdTable
                {...(
                    hasRowSelection ?
                        {
                            rowSelection: {
                                type: "checkbox",
                                ...rowSelection,
                                selectedRowKeys: selectedRowKeys,
                            }
                        } :
                        {}
                )}
                rowKey={record => record?.id}
                dataSource={filteredRows} // Use filteredRows as data source
                columns={tableColumns}
                pagination={{ position: ["bottomCenter"] }}
                rowClassName={rowClassName}
                responsiveArray={responsiveArray}
                responsiveMap={responsiveMap}
                scroll={{ x: true }}
                tableLayout="auto"

            />
        </div>
    )
}

export default Table;
