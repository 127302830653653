import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// MUI | ANT-D :
import { Button, Input } from 'antd';

// Helpers
import Fade from 'react-reveal/Fade';

// Assets | ICONS :
import Google from '../../../Assets/Images/google.svg';
import EmailIcon from '../../../Assets/Images/emailIcon.png';
import MuslimMan from "../../../Assets/Images/muslimMan.png"

// Redux :
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../Redux/Slice/userData"

// API :
import { GoogleLoginAPI, LoginAPI } from "../../../API/auth";

// helpers :
import { toast } from 'react-toastify';
import JWTDecode from 'jwt-decode';

// Google Login API :
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google"

// CSS :
import "./Login.scss";
import MessagePop from './../../../Components/MessagePopup/message';







const Login = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const Location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const isSite = new URLSearchParams(Location.search).get("s")
    const redirectLocation = new URLSearchParams(Location.search).get("l")
    const redirectId = new URLSearchParams(Location.search).get("id")


    // console.log("-------------8888888888888888888 ", isSite, redirectLocation, redirectId);

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const [stepStatus, setStepStatus] = useState(false)
    const [formError, setFormError] = useState({
        email: null,
        password: null
    })

    const [loading, setloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Your Application is Under Review");


    const enteringFormData = (event) => {
        let { name, value } = event.target;

        switch (name) {
            case "password":
                if (value.length <= 7) {
                    setFormError({
                        ...formError,
                        password: "Password length must be greater than 7 characters"
                    })
                } else {
                    setFormError({
                        ...formError,
                        password: null
                    })
                }
                break;
            case "email":
                if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                    setFormError({
                        ...formError,
                        email: "Please enter a valid email address."
                    })
                } else {
                    setFormError({
                        ...formError,
                        email: null
                    })
                }
                break;

            default:
                break;
        }

        setFormData({
            ...formData,
            [name]: value
        })
    };


    const handleLogin = async (event) => {
        setloading(true)
        let res = await LoginAPI({ email: formData.email, password: formData.password })
        if (res.error != null) {
            setErrorMessage(res.error);
            setIsModalOpen(true);
            setTimeout(() => {

                setIsModalOpen(false);

            }, 3000);


        } else {
            const responseData = res.data;
            toast.success(responseData.message);

            if (responseData.result.status === "blocked") {
                toast.error("User is Blocked");
                setloading(false)
                return; // Don't proceed further
            }
            // toast.success(res.data.message);
            Dispatch(userDataActions.setUserData(res?.data?.result))
            let token = res?.data?.result?.token
            localStorage.setItem("madrasaToken", token)
            localStorage.setItem("madrasaUserData", JSON.stringify(res?.data?.result))
            setTimeout(() => {
                if (isSite) {
                    let url = window.location?.ClientURL
                    window.open(redirectLocation ? redirectId ? `${url}/${redirectLocation}?id=${redirectId}` : `${url}/${redirectLocation}` : url)
                    window.location.href = "/"
                } else {
                    window.location.href = "/"
                }
            }, 500);
        }
        setloading(false)
    }

    // const handleLogin = async (event) => {
    //     event.preventDefault(); // Prevent default form submission behavior
    //     setloading(true);

    //     try {
    //         let res = await LoginAPI({ email: formData.email, password: formData.password });

    //         if (res.error != null) {
    //             toast.error(res.error);
    //         } else {
    //             const responseData = res.data;
    //             toast.success(responseData.message);

    //             if (responseData.result.status === "blocked") {
    //                 toast.error("User is Blocked");
    //                 return; // Don't proceed further
    //             }

    //             Dispatch(userDataActions.setUserData(responseData.result));
    //             const token = responseData.result.token;
    //             localStorage.setItem("madrasaToken", token);
    //             localStorage.setItem("madrasaUserData", JSON.stringify(responseData.result));

    //             setTimeout(() => {
    //                 const url = window.location?.ClientURL;
    //                 const redirectUrl = redirectLocation ? (redirectId ? `${url}/${redirectLocation}?id=${redirectId}` : `${url}/${redirectLocation}`) : url;
    //                 window.open(redirectUrl, "_self"); // Open the redirect URL in the same window
    //             }, 500);
    //         }
    //     } catch (error) {
    //         console.error("Login error:", error);
    //         toast.error("An error occurred during login.");
    //     } finally {
    //         setloading(false);
    //     }
    // };

    const handleGoogleLogin = async (data) => {
        // console.log("----------- GOOGLE API ------------", data);
        if (data?.credential) {
            // let UserData = JWTDecode(data.credential)
            let res = await GoogleLoginAPI(data.credential)
            if (res.error != null) {
                if (!res.error?.message == "User Not Found") {
                    setErrorMessage(res.error?.message);
                    setIsModalOpen(true);
                    setTimeout(() => {

                        setIsModalOpen(false);

                    }, 3000);

                    Navigate("/register", { state: res.error?.userData })
                }
                else {
                    setErrorMessage(res.error?.message);
                    setIsModalOpen(true);
                    setTimeout(() => {

                        setIsModalOpen(false);

                    }, 3000);

                }
            } else {
                toast.success(res.data.message);
                Dispatch(userDataActions.setUserData(res?.data?.result))
                let token = res?.data?.result?.token
                localStorage.setItem("madrasaToken", token)
                localStorage.setItem("madrasaUserData", JSON.stringify(res?.data?.result))
                setTimeout(() => {
                    if (isSite) {
                        let url = window.location?.ClientURL
                        window.open(redirectLocation ? redirectId ? `${url}/${redirectLocation}?id=${redirectId}` : `${url}/${redirectLocation}` : url)
                        window.location.href = "/"
                    } else {
                        window.location.href = "/"
                    }
                }, 500);
            }
        } else {
            toast.error("Google Login Fail")
        }
    }

    const Customerlogin = useGoogleLogin({
        onSuccess: (token) => handleGoogleLogin(token),
    })
    const registerFun = () => {
        Navigate('/register')
    }
    const forgetFun = () => {
        Navigate('/forget')
    }


    useEffect(() => {
        if ((!formData.email || formError.email) || (!formData.password || formError.password)) {
            setStepStatus(false)
        } else {
            setStepStatus(true)
        }
    }, [formData])
    return (
        <>
            <div className='loginContainer'>
                <div className="leftSection">
                    <form action="users">
                        <div className="heading">Log in</div>
                        <div className="flexFields">
                            <div className="field inputBox">
                                <div className="name"> Email Address </div>
                                <Input className='loginInput' type="text" placeholder='Your email address' name="email" onChange={enteringFormData} value={formData.email} suffix={<> <img src={EmailIcon} alt="" /> </>} onPressEnter={handleLogin} />
                                {formError.email && <div className="errorMessage">{formError.email}</div>}
                            </div>
                            <div className="field inputBox">
                                <div className="name"> Password </div>
                                <Input.Password
                                    className='loginInput'
                                    placeholder="Your Password"
                                    name='password'
                                    onChange={enteringFormData}
                                    value={formData.password}
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                    onPressEnter={handleLogin}
                                />
                                {formError.password && <div className="errorMessage">{formError.password}</div>}

                            </div>
                            <div className="forgotPass">
                                <p className='cursor' onClick={forgetFun}>Forgot Password?</p>
                            </div>
                            <div className="loginButton">
                                <Button type='submit' disabled={!stepStatus} loading={loading} className='yellowBtn' onClick={handleLogin}>Login</Button>
                                {isModalOpen && <MessagePop isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message={errorMessage} type="warning" />}

                                <p>New to the Madrasa? &nbsp;<a className='signup cursor' onClick={registerFun} > <strong >Register</strong></a> </p>
                            </div>
                        </div>
                        <div className="authButton">
                            {/* <div className="authButton">
                                <div className="google cursor" onClick={() => Customerlogin()}><img src={Google} alt="" /> Sign in with Google</div>
                            </div> */}
                            <GoogleLogin
                                size='large'
                                onSuccess={handleGoogleLogin}
                                login_uri='https://dashboard.madrasa.io/login'
                            />
                            {/* <div className="google cursor"><img src={Google} alt="" /> Sign in with Google</div> */}
                            {/* <div className="fb cursor"><FaFacebookF style={{ color: "#fff", fontSize: "20px" }} /> Sign in with Facebook</div> */}
                        </div>
                    </form>
                </div>
                <div className="rightSection">
                    <div className="madrasaLogo">
                        <Fade left>
                            <img src={MuslimMan} alt="" />
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login