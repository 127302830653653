import React from 'react'

// MUI | ANT-D :
import { Select } from 'antd';

// CSS :
import './Information.scss'





const Infomation = ({ postData }) => {

    return (
        <>
            <div className='blogForm'>
                <div className="mb-3">
                    <label className="form-label heading">Categories</label>
                    <Select
                        bordered={false}
                        mode="tags"
                        placeholder="Category"
                        value={postData?.categories.map(cat => cat?._id)}
                        options={postData?.categories?.map(cat => ({ value: cat?._id, label: cat?.name }))}
                        style={{
                            width: '100%',
                        }}
                        className="selectStyleReviewPage"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label heading">Tags</label>
                    <Select
                        bordered={false}
                        mode="tags"
                        placeholder="Tag"
                        value={postData?.tags.map(tag => tag?._id)}
                        options={postData?.tags?.map(tag => ({ value: tag?._id, label: tag?.name }))}
                        style={{
                            width: '100%',
                        }}
                        className="selectStyleReviewPage"
                    />
                </div>
            </div>
        </>
    )
}

export default Infomation
