// quillConfig.js
import ReactQuill from 'react-quill';
import './fonts.css';


const fonts = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida', 'tahoma', 'times-new-roman', 'trebuchet', 'verdana'];

// Register custom fonts with Quill
const registerFonts = () => {
    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = fonts;
    ReactQuill.Quill.register(Font, true);
};

// Define modules and formats
export const quillModules = {
    toolbar: {
        container: [
            [{ 'font': fonts }],
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image']
        ],
        handlers: {
            'font': function (value) {
                if (value) {
                    this.quill.format('font', value);
                } else {
                    this.quill.format('font', false);
                }
            }
        }
    }
};

export const quillFormats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link',
    'image'
];

// Export font registration function for initialization
export { registerFonts };
