import React, { useState, useEffect } from "react";

// ANT-D | MUI :
import { Button, Input, Upload, message } from "antd";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

// ICONS | Assets :
import { BsArrowLeftShort } from "react-icons/bs";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

// APIs :
import { CreatEventAPI, UpdateEventAPI } from "API/event";
// Helpers :
import { toast } from "react-toastify";
import dayjs from "dayjs";

// CSS :
import "./AddEvent.scss";
import { timeZones } from './../../../Users/Component/ProfileUserEdit/Helper/TimeZone';
import { StaticDateTimePicker } from "@mui/x-date-pickers";




const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const AddEvents = ({ closePage, selectedEvent }) => {

  const [formData, setFormData] = useState({
    title: "",
    country: "",
    city: "",
    description: "",
    date: dayjs()
  })
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null)

  const [datePickerTab, setDatePickerTab] = useState("day");

  const [loading, setLoading] = useState(false)


  const enteringFormData = (event) => {
    let { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const enteringDate = (event) => {
    setFormData({
      ...formData,
      date: event
    })
    setDatePickerTab("day")
  };

  const handleUploadChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
    });

    setFile(info?.file?.originFileObj || null)
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );


  const handleCreateEvent = async () => {
    setLoading(true)
    let data = new FormData();
    data.append("title", formData?.title);
    data.append("country", formData?.country);
    data.append("city", formData?.city);
    data.append("date", formData?.date);
    data.append("type", "event");
    data.append("description", formData?.description);
    data.append("file", file);

    let res;
    if (selectedEvent) {

      res = await UpdateEventAPI(selectedEvent?._id, data)
    } else {
      res = await CreatEventAPI(data)
    }

    if (res.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res.data?.message)
      closePage()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (selectedEvent) {
      setFormData({
        ...selectedEvent,
        date: dayjs(selectedEvent.date)
      })
      setImageUrl(selectedEvent?.image?.url)
    } else {
      setFormData({
        title: "",
        country: "",
        city: "",
        description: "",
        date: dayjs()
      })
      setImageUrl(null)
    }
  }, [selectedEvent])

  return (
    <>
      <div className="AddEventMain">
        <div className="flexLineSpace">
          <div className="heading upper"><BsArrowLeftShort className='icon cursor' onClick={closePage} /><div className="head"> {selectedEvent ? "Event Details" : "Add Event"} </div></div>
        </div>
        <div className="AddBodyMain">
          <div className="Events">
            <div className="imgContainer">
              <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleUploadChange}
              >
                {imageUrl ? (
                  <div className="imgBox">
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>

            <div className="inputFields">
              <div className="lableName">Event Title</div>
              <Input
                size="large"
                className="eventInput"
                type="text"
                placeholder="Event Title"
                value={formData?.title}
                name="title"
                onChange={enteringFormData}
              />

              <div className="lableName">Country</div>
              <Input
                size="large"
                className="eventInput"
                type="text"
                placeholder="Event Country"
                value={formData?.country}
                name="country"
                onChange={enteringFormData}
              />
              <div className="flexLine flexColumn-600">
                <div style={{ width: "100%" }}>
                  <div className="lableName">City</div>
                  <Input
                    size="large"
                    className="eventInput"
                    type="text"
                    placeholder="Event City"
                    value={formData?.city}
                    name="city"
                    onChange={enteringFormData}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <div className="lableName">Date</div>
                  <Input
                    size="large"
                    className="eventInput"
                    type="text"
                    placeholder="Event Date"
                    value={formData?.date?.format("MMM DD, YYYY. | hh:mm A")}
                    name="date"
                  // onChange={enteringFormData}
                  />
                </div>
              </div>

              <div className="lableName">Event Description</div>
              <Input.TextArea
                rows={6}
                size="large"
                className="eventDescription"
                type="text"
                placeholder="Event Deescription"
                value={formData?.description}
                name="description"
                onChange={enteringFormData}
              />

              <div className="BtnReadMore">
                <Button className="yellowGraBtn" loading={loading} onClick={handleCreateEvent}> {selectedEvent ? "Update" : "Create"} Event</Button>
              </div>

            </div>
          </div>

          <div className="CalenderMain">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <StaticDateTimePicker defaultValue={dayjs()} openTo={datePickerTab} onViewChange={(view) => setDatePickerTab(view)} disablePast={true} slotProps={{ actionBar: { actions: ["accept"] } }} onAccept={enteringDate} />
              </DemoContainer>
            </LocalizationProvider>
          </div>

        </div>
      </div>
    </>
  );
};


export default AddEvents;
