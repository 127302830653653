import React, { useState, useEffect } from 'react';
import "./../.../../../Home/Components/ReportCards/Cards.scss";
import { Skeleton } from 'antd';
import { GrCreditCard } from "react-icons/gr";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";




const Cards = ({ balance, title }) => {

    if (balance == null || isNaN(balance)) {
        balance = 0;
    }






    return (
        <div className="cardsContainer">

            <div className="hoverEffect">
                <div className="card">
                    <div className="iconBox special">
                        <GrCreditCard size={26} color="#575757" />

                    </div>
                    <div className="detials">
                        <div className="value">
                            $ {balance}

                        </div>
                        <div className="title">{title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cards;
