import './AddWithdrawalRequest.scss'
import React, { useEffect, useState } from "react";

// Icons
import { GrCreditCard } from "react-icons/gr";
import { Button, Select, Input, Upload, message } from "antd";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
// Helper
import ReactFlagsSelect from "react-flags-select";
import { addWithdrawalReq } from 'API/withdrawal';
import { toast } from 'react-toastify';
import Card from "./Cards"
import { CgArrowsExchangeV } from 'react-icons/cg';


const AddWithdrawalRequest = ({ userwithdrawal, closePage, userBalance, setCurrentPage, userPendingWithdrawal }) => {

    // const totalwithdrawl = userwithdrawal;
    // alert(totalwithdrawl);

    const [countryselected, setcountrySelected] = useState("");
    const [currenyselected, setcurrenySelected] = useState("");
    const [loading, setloading] = useState(false)
    const [countryselect, setcountrySelect] = useState("");
    const [selectcurreny, setselectcurreny] = useState("");





    const [formData, setFormData] = useState({
        bank_name: '',
        branch_name: '',
        currency: '',
        country: '',
        branch_code: '',
        iban: '',
        account_number: '',
        amount: '',
        status: "pending",
    });
    const [formError, setFormError] = useState({
        bank_name: null,
        branch_name: null,
        currency: null,
        country: null,
        branch_code: null,
        iban: null,
        account_number: null,
        amount: null,
    })

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "bank_name":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        bank_name: "A Bank Name is requried."
                    })
                } else if (!/^[A-Za-z]*$/.test(value)) {
                    setFormError({
                        ...formError,
                        bank_name: "You can't use numbers & special characters."
                    })
                } else {
                    setFormError({
                        ...formError,
                        bank_name: null
                    })
                }
                break;
            case "branch_name":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        branch_name: "A Branch Name is requried."
                    })
                } else if (!/^[A-Za-z]*$/.test(value)) {
                    setFormError({
                        ...formError,
                        branch_name: "You can't use numbers & special characters."
                    })
                } else {
                    setFormError({
                        ...formError,
                        branch_name: null
                    })
                }
                break;
            case "branch_code":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        branch_code: "Branch Code is requried."
                    })
                } else {
                    setFormError({
                        ...formError,
                        branch_code: null
                    })
                }
                break;
            case "iban":
                if (value.length <= 34) {
                    setFormError({
                        ...formError,
                        iban: "Enter Valid IBAN Number"
                    })
                } else {
                    setFormError({
                        ...formError,
                        iban: null
                    })
                }
                break;
            case "account_number":
                if (value.length <= 12) {
                    setFormError({
                        ...formError,
                        account_number: "Enter Valid Bank Account Number "
                    })
                } else {
                    setFormError({
                        ...formError,
                        account_number: null
                    })
                }
                break;
            case "amount":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        amount: "Ammount Feild Required"
                    })
                } else {
                    setFormError({
                        ...formError,
                        amount: null
                    })
                }
                break;
            default:
                break;
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    useEffect(() => {
        setFormData({ ...formData, country: countryselect, currency: selectcurreny });
    }, [countryselect, selectcurreny])

    const handleSubmit = async (e) => {
        e.preventDefault();

        // if
        const res = await addWithdrawalReq(formData)
        if (res.data != null) {
            toast.success("Request Posted")
            setCurrentPage("all")
        } else toast.error(res.error)
    };

    const currency = [
        { label: "Afghan Afghani", value: "AFA" },
        { label: "Albanian Lek", value: "ALL" },
        { label: "Algerian Dinar", value: "DZD" },
        { label: "Angolan Kwanza", value: "AOA" },
        { label: "Argentine Peso", value: "ARS" },
        { label: "Armenian Dram", value: "AMD" },
        { label: "Aruban Florin", value: "AWG" },
        { label: "Australian Dollar", value: "AUD" },
        { label: "Azerbaijani Manat", value: "AZN" },
        { label: "Bahamian Dollar", value: "BSD" },
        { label: "Bahraini Dinar", value: "BHD" },
        { label: "Bangladeshi Taka", value: "BDT" },
        { label: "Barbadian Dollar", value: "BBD" },
        { label: "Belarusian Ruble", value: "BYR" },
        { label: "Belgian Franc", value: "BEF" },
        { label: "Belize Dollar", value: "BZD" },
        { label: "Bermudan Dollar", value: "BMD" },
        { label: "Bhutanese Ngultrum", value: "BTN" },
        { label: "Bitcoin", value: "BTC" },
        { label: "Bolivian Boliviano", value: "BOB" },
        { label: "Bosnia-Herzegovina Convertible Mark", value: "BAM" },
        { label: "Botswanan Pula", value: "BWP" },
        { label: "Brazilian Real", value: "BRL" },
        { label: "British Pound Sterling", value: "GBP" },
        { label: "Brunei Dollar", value: "BND" },
        { label: "Bulgarian Lev", value: "BGN" },
        { label: "Burundian Franc", value: "BIF" },
        { label: "Cambodian Riel", value: "KHR" },
        { label: "Canadian Dollar", value: "CAD" },
        { label: "Cape Verdean Escudo", value: "CVE" },
        { label: "Cayman Islands Dollar", value: "KYD" },
        { label: "CFA Franc BCEAO", value: "XOF" },
        { label: "CFA Franc BEAC", value: "XAF" },
        { label: "CFP Franc", value: "XPF" },
        { label: "Chilean Peso", value: "CLP" },
        { label: "Chilean Unit of Account", value: "CLF" },
        { label: "Chinese Yuan", value: "CNY" },
        { label: "Colombian Peso", value: "COP" },
        { label: "Comorian Franc", value: "KMF" },
        { label: "Congolese Franc", value: "CDF" },
        { label: "Costa Rican Colón", value: "CRC" },
        { label: "Croatian Kuna", value: "HRK" },
        { label: "Cuban Convertible Peso", value: "CUC" },
        { label: "Czech Republic Koruna", value: "CZK" },
        { label: "Danish Krone", value: "DKK" },
        { label: "Djiboutian Franc", value: "DJF" },
        { label: "Dominican Peso", value: "DOP" },
        { label: "East Caribbean Dollar", value: "XCD" },
        { label: "Egyptian Pound", value: "EGP" },
        { label: "Eritrean Nakfa", value: "ERN" },
        { label: "Estonian Kroon", value: "EEK" },
        { label: "Ethiopian Birr", value: "ETB" },
        { label: "Euro", value: "EUR" },
        { label: "Falkland Islands Pound", value: "FKP" },
        { label: "Fijian Dollar", value: "FJD" },
        { label: "Gambian Dalasi", value: "GMD" },
        { label: "Georgian Lari", value: "GEL" },
        { label: "German Mark", value: "DEM" },
        { label: "Ghanaian Cedi", value: "GHS" },
        { label: "Gibraltar Pound", value: "GIP" },
        { label: "Greek Drachma", value: "GRD" },
        { label: "Guatemalan Quetzal", value: "GTQ" },
        { label: "Guinean Franc", value: "GNF" },
        { label: "Guyanaese Dollar", value: "GYD" },
        { label: "Haitian Gourde", value: "HTG" },
        { label: "Honduran Lempira", value: "HNL" },
        { label: "Hong Kong Dollar", value: "HKD" },
        { label: "Hungarian Forint", value: "HUF" },
        { label: "Icelandic Króna", value: "ISK" },
        { label: "Indian Rupee", value: "INR" },
        { label: "Indonesian Rupiah", value: "IDR" },
        { label: "Iranian Rial", value: "IRR" },
        { label: "Iraqi Dinar", value: "IQD" },
        { label: "Israeli New Sheqel", value: "ILS" },
        { label: "Italian Lira", value: "ITL" },
        { label: "Jamaican Dollar", value: "JMD" },
        { label: "Japanese Yen", value: "JPY" },
        { label: "Jordanian Dinar", value: "JOD" },
        { label: "Kazakhstani Tenge", value: "KZT" },
        { label: "Kenyan Shilling", value: "KES" },
        { label: "Kuwaiti Dinar", value: "KWD" },
        { label: "Kyrgystani Som", value: "KGS" },
        { label: "Laotian Kip", value: "LAK" },
        { label: "Latvian Lats", value: "LVL" },
        { label: "Lebanese Pound", value: "LBP" },
        { label: "Lesotho Loti", value: "LSL" },
        { label: "Liberian Dollar", value: "LRD" },
        { label: "Libyan Dinar", value: "LYD" },
        { label: "Litecoin", value: "LTC" },
        { label: "Lithuanian Litas", value: "LTL" },
        { label: "Macanese Pataca", value: "MOP" },
        { label: "Macedonian Denar", value: "MKD" },
        { label: "Malagasy Ariary", value: "MGA" },
        { label: "Malawian Kwacha", value: "MWK" },
        { label: "Malaysian Ringgit", value: "MYR" },
        { label: "Maldivian Rufiyaa", value: "MVR" },
        { label: "Mauritanian Ouguiya", value: "MRO" },
        { label: "Mauritian Rupee", value: "MUR" },
        { label: "Mexican Peso", value: "MXN" },
        { label: "Moldovan Leu", value: "MDL" },
        { label: "Mongolian Tugrik", value: "MNT" },
        { label: "Moroccan Dirham", value: "MAD" },
        { label: "Mozambican Metical", value: "MZM" },
        { label: "Myanmar Kyat", value: "MMK" },
        { label: "Namibian Dollar", value: "NAD" },
        { label: "Nepalese Rupee", value: "NPR" },
        { label: "Netherlands Antillean Guilder", value: "ANG" },
        { label: "New Taiwan Dollar", value: "TWD" },
        { label: "New Zealand Dollar", value: "NZD" },
        { label: "Nicaraguan Córdoba", value: "NIO" },
        { label: "Nigerian Naira", value: "NGN" },
        { label: "North Korean Won", value: "KPW" },
        { label: "Norwegian Krone", value: "NOK" },
        { label: "Omani Rial", value: "OMR" },
        { label: "Pakistani Rupee", value: "PKR" },
        { label: "Panamanian Balboa", value: "PAB" },
        { label: "Papua New Guinean Kina", value: "PGK" },
        { label: "Paraguayan Guarani", value: "PYG" },
        { label: "Peruvian Nuevo Sol", value: "PEN" },
        { label: "Philippine Peso", value: "PHP" },
        { label: "Polish Zloty", value: "PLN" },
        { label: "Qatari Rial", value: "QAR" },
        { label: "Romanian Leu", value: "RON" },
        { label: "Russian Ruble", value: "RUB" },
        { label: "Rwandan Franc", value: "RWF" },
        { label: "Salvadoran Colón", value: "SVC" },
        { label: "Samoan Tala", value: "WST" },
        { label: "São Tomé and Príncipe Dobra", value: "STD" },
        { label: "Saudi Riyal", value: "SAR" },
        { label: "Serbian Dinar", value: "RSD" },
        { label: "Seychellois Rupee", value: "SCR" },
        { label: "Sierra Leonean Leone", value: "SLL" },
        { label: "Singapore Dollar", value: "SGD" },
        { label: "Slovak Koruna", value: "SKK" },
        { label: "Solomon Islands Dollar", value: "SBD" },
        { label: "Somali Shilling", value: "SOS" },
        { label: "South African Rand", value: "ZAR" },
        { label: "South Korean Won", value: "KRW" },
        { label: "South Sudanese Pound", value: "SSP" },
        { label: "Special Drawing Rights", value: "XDR" },
        { label: "Sri Lankan Rupee", value: "LKR" },
        { label: "St. Helena Pound", value: "SHP" },
        { label: "Sudanese Pound", value: "SDG" },
        { label: "Surinamese Dollar", value: "SRD" },
        { label: "Swazi Lilangeni", value: "SZL" },
        { label: "Swedish Krona", value: "SEK" },
        { label: "Swiss Franc", value: "CHF" },
        { label: "Syrian Pound", value: "SYP" },
        { label: "Tajikistani Somoni", value: "TJS" },
        { label: "Tanzanian Shilling", value: "TZS" },
        { label: "Thai Baht", value: "THB" },
        { label: "Tongan Pa'anga", value: "TOP" },
        { label: "Trinidad & Tobago Dollar", value: "TTD" },
        { label: "Tunisian Dinar", value: "TND" },
        { label: "Turkish Lira", value: "TRY" },
        { label: "Turkmenistani Manat", value: "TMT" },
        { label: "Ugandan Shilling", value: "UGX" },
        { label: "Ukrainian Hryvnia", value: "UAH" },
        { label: "United Arab Emirates Dirham", value: "AED" },
        { label: "Uruguayan Peso", value: "UYU" },
        { label: "US Dollar", value: "USD" },
        { label: "Uzbekistan Som", value: "UZS" },
        { label: "Vanuatu Vatu", value: "VUV" },
        { label: "Venezuelan BolÃvar", value: "VEF" },
        { label: "Vietnamese Dong", value: "VND" },
        { label: "Yemeni Rial", value: "YER" },
        { label: "Zambian Kwacha", value: "ZMK" },
        { label: "Zimbabwean dollar", value: "ZWL" }
    ]


    return (
        <>
            <div className="main">
                <div className="form-body">
                    <div className="heading">
                        <BsArrowLeftShort className="icon" onClick={closePage} />
                        <h2>Add Withdrawal Request</h2>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Card balance={userBalance?.balance} title="Total Balance"></Card>
                        <Card balance={userwithdrawal} title="Total Withdrawal"></Card>
                        <Card balance={userPendingWithdrawal} title="Pending Withdrawal"></Card>
                    </div>
                    {/* <div className="top-cards">
                        <div className="card active">
                            <div className="icon-box">
                                <div className="icon">
                                    <GrCreditCard />
                                </div>
                                <h3>Current Balance</h3>
                            </div>
                            <div className="amount">
                                <h1> $ {userBalance?.balance}</h1>
                            </div>
                        </div>

                        <div className="card">
                            <div className="icon-box">
                                <div className="icon">
                                    <GrCreditCard />
                                </div>
                                <h3>Total Withdrawal Amount</h3>
                            </div>
                            <div className="amount">
                                <h1> $ {userwithdrawal}</h1>
                            </div>

                        </div>
                        <div className="card">
                            <div className="icon-box">
                                <div className="icon">
                                    <GrCreditCard />
                                </div>
                                <h3>Pending Withdrawal Amount</h3>
                            </div>
                            <div className="amount">
                                <h1> $ {userPendingWithdrawal}</h1>
                            </div>

                        </div>
                    </div> */}
                    <div className="form-section">
                        <div className="form-heading">
                            <h2>Withdrawal Request</h2>
                        </div>
                        <div className="form">
                            <div className="form-row">
                                <div className="input-group">
                                    <div className="lableName">Select Country:</div>
                                    <ReactFlagsSelect
                                        selected={countryselect}
                                        onSelect={setcountrySelect}
                                        name="country"
                                        value={formData.country}
                                        searchable={true}
                                        customLabels={{ "US": "EN-US", "GB": "EN-GB", "FR": "FR", "DE": "DE", "IT": "IT" }}
                                        selectButtonClassName="custom-react-flags-select" // Add a custom class name
                                    />
                                </div>

                                {/* <div className="input-group" id='gender'>
                                    <div className="lableName">Select Currency:</div>
                                    <Select
                                        placeholder="Select Currency"
                                        className="registerSelector"
                                        onChange={setselectcurreny}
                                        getPopupContainer={() => document.getElementById('gender')}
                                        dropdownStyle={{ zIndex: "5000" }}
                                        options={currency}
                                        name="currency"
                                        value={formData.currency}
                                    />
                                </div> */}
                                <div className="field1 field" id='gender'>
                                    <div className="lableName">Select Currency:</div>
                                    <div className="inputselect">

                                        <Select
                                            placeholder='Select Country'
                                            getPopupContainer={() => document.getElementById('gender')}

                                            value={formData.currency}
                                            // defaultValue="Select"
                                            bordered={false}
                                            className='selector'
                                            onChange={setselectcurreny}
                                            options={currency}
                                            name="currency"


                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="input-group">
                                    <div className="lableName">Bank Name:</div>
                                    <Input
                                        size="large"
                                        className="withdrawalInput "
                                        type="text"
                                        placeholder="Enter your Bank Name"
                                        name="bank_name"
                                        onChange={handleChange}
                                        value={formData.bank_name}
                                    // onChange={handleChange}
                                    />
                                    {formError && <p style={{ color: "red" }}>{formError.bank_name}</p>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="input-group">
                                    <div className="lableName">Branch Name:</div>
                                    <Input
                                        size="large"
                                        className="withdrawalInput"
                                        type="text"
                                        placeholder="Enter Your Branch Name"
                                        value={formData.branchName}
                                        name="branch_name"
                                        onChange={handleChange}
                                    // onChange={handleChange}
                                    />
                                    {formError && <p style={{ color: "red" }}>{formError.branch_name}</p>}
                                </div>
                                <div className="input-group">
                                    <div className="lableName">Branch Code:</div>
                                    <Input
                                        size="large"
                                        className="withdrawalInput"
                                        type="text"
                                        placeholder="Enter Your Branch Code"
                                        value={formData.branch_code}
                                        name="branch_code"
                                        onChange={handleChange}
                                    // onChange={handleChange}
                                    />
                                    {formError && <p style={{ color: "red" }}>{formError.branch_code}</p>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="input-group">
                                    <div className="lableName">IBAN Number:</div>
                                    <Input
                                        size="large"
                                        className="withdrawalInput"
                                        type="text"
                                        placeholder="Enter Your IBAN Number"
                                        value={formData.iban}
                                        name="iban"
                                        onChange={handleChange}
                                    // onChange={handleChange}
                                    />
                                    {formError && <p style={{ color: "red" }}>{formError.iban}</p>}
                                </div>
                                <div className="input-group">
                                    <div className="lableName">Account Number:</div>
                                    <Input
                                        size="large"
                                        className="withdrawalInput"
                                        type="text"
                                        placeholder="5425 **** **** ****"
                                        value={formData.account_number}
                                        name="account_number"
                                        onChange={handleChange}
                                    // onChange={handleChange}
                                    />
                                    {formError && <p style={{ color: "red" }}>{formError.account_number}</p>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="input-group">
                                    <div className="lableName">Amount:</div>
                                    <Input
                                        size="large"
                                        className="withdrawalInput"
                                        type="text"
                                        placeholder="Enter Your Amount"
                                        value={formData.amount}
                                        name="amount"
                                        onChange={handleChange}
                                    // onChange={handleChange}
                                    />
                                    {formError && <p style={{ color: "red" }}>{formError.amount}</p>}
                                </div>
                            </div>
                            <div className="submit-button">
                                <Button className='yellowGraBtn createRoleBtn' style={{ width: "120px" }} loading={loading} onClick={handleSubmit}> Save </Button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
export default AddWithdrawalRequest


