import React, { useEffect, useState } from "react";

// MUI | ANT-D :
import { DatePicker, Progress, Skeleton } from "antd";

// Components :
import Cards from "./Components/ReportCards/Cards";
import LineChart from "./Components/SalesReportChart/SalesChart";
import DonutChart from "./Components/DonutChart/DonutChart";

// CSS :
import "./Home.scss";
import { Blogger, Book1 } from "iconsax-react";
import { MdOutlineSubscriptions } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import DashboardStaticsAPI from "API/dashboard";
import { toast } from "react-toastify";

let { RangePicker } = DatePicker;

const Home = () => {
  const [toggle, setToggle] = useState(null);
  const [staticData, setStaticData] = useState(null);

  const handleToggle = (e) => {
    e.preventDefault();
    let innerText = e.target.textContent;
    let innerClass = e.target.className;
    let includes = innerClass.includes("active");

    if (!includes) {
      setToggle(innerText);
    } else {
      setToggle(null);
    }
  };

  const gettingDashboardData = async () => {
    let res = await DashboardStaticsAPI();
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setStaticData(res.data?.result || null);
    }
  };

  useEffect(() => {
    gettingDashboardData();
  }, []);

  return (
    <div className="dashboardHomeContainer">
      <div className="box">
        <Cards staticsData={staticData} />

        {staticData ? (
          <div className="reportBox">
            <div className="donutBox">
              <div className="flexLineSpace">
                <div className="subHeading">Users Report</div>
              </div>
              <div className="donuts">
                <DonutChart staticsData={staticData?.users} />
              </div>
            </div>

            <div className="reportCard">
              <div className="twoSides">
                <div className="cardContent">
                  <div className="cardLogo">
                    <Book1 size="24" color="#42464E" />
                  </div>
                  <div className="cardDetails">
                    <h5>Total Books</h5>
                    {staticData?.books?.total ? (
                      <p>
                        There are Total <b>{staticData?.books?.total}</b> Books.
                      </p>
                    ) : (
                      <p>
                       There are Total <b>{staticData?.books?.total}</b> Books.
                      </p>
                    )}
                  </div>
                </div>
                <div className="cardContent">
                  <div className="cardLogo">
                    <Blogger size="24" color="#42464E" />
                  </div>
                  <div className="cardDetails">
                    <h5>Blogs</h5>
                    {staticData?.blogs?.total ? (
                      <p>
                        There are Total <b>{staticData?.blogs?.total || 0}</b> Blogs.
                      </p>
                    ) : (
                      <p>
                        There are Total <b>{staticData?.blogs?.total || 0}</b> Blogs.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="twoSides">
                <div className="cardContent">
                  <div className="cardLogo">
                    <MdOutlineSubscriptions size="24" color="#42464E" />
                  </div>
                  <div className="cardDetails">
                    <h5>Subscriptions</h5>
                    {staticData?.subscription?.total ? (
                      <p>
                        There are Total <b>{staticData?.subscription?.total || 0}</b> Subscriptions.
                      </p>
                    ) : (
                      <p>
                        There are Total <b>{staticData?.subscription?.total || 0}</b> Subscriptions.
                      </p>
                    )}
                  </div>
                </div>
                <div className="cardContent">
                  <div className="cardLogo">
                    <FiUsers size="24" color="#42464E" />
                  </div>
                  <div className="cardDetails">
                    <h5>Users</h5>
                    {staticData?.users?.total ? (
                      <p>
                        There are Total <b>{staticData?.users?.total}</b> Users.
                      </p>
                    ) : (
                      <p>
                      There are Total <b>{staticData?.users?.total}</b> Users.
                    </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="reportBox">
            <div >
              <Skeleton active />

            </div>
            <div >
              <Skeleton active />

            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
