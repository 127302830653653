import React, { useState } from "react";
import { List, Input, Button, Avatar } from "antd";
import './Chat.scss'
import { SendOutlined } from '@ant-design/icons';

const ChatUI = ({ messages, setMessages, newMessage, setNewMessage, handleSend, UserId }) => {

    return (
        <>
            <div className="chat-app">
                {/* <List
                    dataSource={messages}
                    renderItem={(item, index) => (
                        <List.Item className={item.isSender ? ' message sender' : ' message receiver'}>
                            <List.Item.Meta class="message-content"
                                description={item.message}
                            />
                        </List.Item>
                    )}
                /> */}
                {
                    Array.isArray(messages) && messages.length >= 1 ? messages.map((data, index) => {
                        return (
                            data?.sender != UserId ?
                                <>
                                    <div class="message receiver">
                                        <div class="message-content">{data?.message}</div>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="message sender">
                                        <div class="message-content">{data?.message}</div>
                                    </div>
                                </>
                        )
                    })
                        :
                        <div className="noMessage">No messages</div>
                }

            </div>
            <div className="input-box">
                <Input
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Write message..."
                    onPressEnter={handleSend}
                />
                <Button type="primary" onClick={handleSend} icon={<SendOutlined />} />
            </div>


        </>
    );
};

export default ChatUI;