import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import SendAnimation from '../../../../Assets/Images/sendAnimation.gif';
import Fade from 'react-reveal/Fade';
import 'react-phone-input-2/lib/bootstrap.css';
import { VerifyEmailOtpAPI } from '../../../../API/auth';
import { toast } from 'react-toastify';
import MessagePop from './../../../../Components/MessagePopup/message';

const RegisterEmail = ({ formData, setFormData, currentStep, handleChangeStep }) => {
  const [otpCode, setOtpCode] = useState("");
  const [otpCodeError, setOtpCodeError] = useState(null);
  const [stepStatus, setStepStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenTimer, setIsModalOpenTimer] = useState(false);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(60); // Timer state set to 60 seconds

  const enteringFormData = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "otpCode":
        if (value.length >= 7 || value.length <= 5) {
          setOtpCodeError("Your code should be composed of 6 numbers.");
        } else {
          setOtpCodeError(null);
        }
        break;
      default:
        break;
    }
    setOtpCode(value);
  };

  const handleNextStep = () => {
    handleChangeStep(currentStep + 1);
  };

  const handleEditEmailStep = () => {
    handleChangeStep(30);
  };

  const handleVerifyOtp = async () => {
    setFormData({ ...formData, otpCode: otpCode });
    setLoading(true);
    let res = await VerifyEmailOtpAPI({
      email: formData.email,
      otp: otpCode
    });
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        handleNextStep();

      }, 2000); // Adjust the delay as needed
    }
    setLoading(false);
  };

  useEffect(() => {
    if ((!otpCode || otpCodeError)) {
      setStepStatus(false);
    } else {
      setStepStatus(true);
    }
  }, [otpCode]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsModalOpenTimer(true);

    }
  }, [timer]);

  return (
    <div className='registerBox'>
      <div className="leftSection">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="heading">Check your email</div>
          <div className="verification">
            <p>Please enter the verification code we sent to:</p>
            <div className="verificationEmail">{formData.email}</div>
          </div>
          <div className="flexFields">
            <div className="field inputBox">
              <div className="name">One Time Password</div>
              <Input.Password className='registerInput' placeholder="Please enter Verification code" name='otpCode' onChange={enteringFormData} value={otpCode} />
              {otpCodeError && <div className="errorMessage">{otpCodeError}</div>}
            </div>
            <div className="registerButton">
              <Button
                disabled={!stepStatus}
                className='yellowBtn'
                loading={loading}
                onClick={handleVerifyOtp}
              >
                Verify email <RightOutlined />
              </Button>
              {isModalOpen && <MessagePop isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message="OTP Verify Successfully " />}
            </div>
          </div>
          <div className="timer">
            <p className='verificationEmail'>Time remaining: {timer} seconds</p>
            {isModalOpenTimer && <MessagePop isModalOpen={isModalOpenTimer} setIsModalOpen={setIsModalOpenTimer} message="OTP has expired please try again!" type="danger" />}

          </div>
          <div className="resendEmail terms" style={{ alignSelf: "start" }}>
            Don't get the email?
            <div className="resend">
              <a className='cursor' onClick={handleEditEmailStep}>Resend Code or edit your Email Address</a>
            </div>
          </div>
        </form>
      </div>
      <div className="rightSection">
        <div className="loginBio">
          <div className="madrasaLogo">
            <Fade left>
              <img src={SendAnimation} alt="" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterEmail;
