import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Tag, Select } from 'antd';
import { Play, Filter } from "iconsax-react";
import Table from '../Users/Component/table/Table';
import PreLoader from "Components/PreLoader/PreLoader";
import { GetAllPaidMeetingsAPI, StartPaidMeetingAPI } from "API/meeting";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ROLES from "Utils/Roles";
import moment from "moment";
import Countdown from "react-countdown";
import "./Meetings.scss";

const Meetings = (props) => {
  let Navigate = useNavigate();
  const [allMeetings, setAllMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState('Select');

  const startMeeting = async (data) => {
    Navigate('/dashboard/call', { state: { meetingId: data?._id } });
  };

  const UserData = useSelector(state => state.userData);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'studentName',
      key: 'studentName',
      render: (_, data) => ([ROLES.Student].includes(UserData?.role?.name)) ? `${data?.admin?.firstName} ${data?.admin?.lastName}` : `${data?.participants[0]?.firstName} ${data?.participants[0]?.lastName}`
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, data) => ([ROLES.Student].includes(UserData?.role?.name)) ? `${data?.admin?.email}` : `${data?.participants[0]?.email}`
    },
    {
      title: 'Course Name',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: 'Study Purpose',
      dataIndex: 'studyPurpose',
      key: 'studyPurpose',
      render: (_, data) => "Scheduled Meeting ",
      ellipsis: true,
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (_, data) => "1Hr",
      ellipsis: true,
    },
    {
      title: 'Schedule',
      dataIndex: 'schedule',
      key: 'schedule',
      render: (_, data) => data?.startDate && moment(data?.startDate).format("DD-MMM-YYYY hh:mm A"),
      ellipsis: true,
    },
    {
      title: 'Starts In',
      dataIndex: 'startIn',
      key: 'startIn',
      render: (_, data) => {
        let currentDate = new Date().getTime();
        let slotTime = new Date(data?.startDate).getTime();
        let DrivedTime = slotTime - currentDate;
        return (
          <Countdown daysInHours={true} date={Date.now() + DrivedTime} renderer={({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              return <>00 : 00</>;
            } else {
              return <>Hr. {hours} : {minutes} : {seconds}</>;
            }
          }} />
        );
      },
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      align: "center",
      render: (_, data) => <Tag className='userTags' color={data?.status == 'completed' ? 'green' : "yellow"}> {data?.status?.toLocaleUpperCase()} </Tag>,
      ellipsis: true,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      align: "center",
      render: (_, data) => {
        let currentDate = new Date().getTime();
        let slotTime = new Date(data?.startDate).getTime();
        let DrivedTime = slotTime - currentDate;
        return (
          <div className="actionBox">
            <Tooltip placement="top" title="Start Meeting">
              <div className="actionBtn" onClick={() => startMeeting(data)}>
                <Play className='icon cursor' />
              </div>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const getingAllMeetings = async () => {
    setLoading(true);
    let res = await GetAllPaidMeetingsAPI();
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setAllMeetings(res.data?.result || []);
    }
    setLoading(false);
  };

  useEffect(() => {
    getingAllMeetings();
  }, [refresh]);

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const filteredMeetings = allMeetings.filter(meeting => {
    if (filter === 'Select') return true;
    if (filter === 'all') return true;
    return meeting.status === filter;
  });

  return (
    <>
      <div className="meetingContainer">
        <div className="flexLineSpace">
          <div className="heading  meetingheading">Meetings</div>
          <Select
            defaultValue="Select"
            className="custom-select"
            onChange={handleFilterChange}
            suffixIcon={<Filter color="#FCD117" />}

          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="pending">Pending</Select.Option>
            <Select.Option value="completed">Completed</Select.Option>
          </Select>
        </div>
        {
          loading ?
            <PreLoader /> :
            <div className="table" style={{ overflow: "hidden" }}>
              <Table rows={filteredMeetings && filteredMeetings?.length >= 1 ? filteredMeetings?.reverse() : []} columns={columns} loading={loading} />
            </div>
        }
      </div>
    </>
  );
};

export default Meetings;
